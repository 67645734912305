import { Col, Image, Modal, Row, Space, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../Provider/AuthContext";
import { UserInfo } from "../../types/model";

export interface ProfileUpdateModalProps {
  open: boolean;
  onOk: (data: UserInfo) => void;
  onCancel: () => void;
  newData: UserInfo;
}

const ProfileUpdateModal: React.FC<ProfileUpdateModalProps> = ({
  open,
  onOk,
  onCancel,
  newData,
}) => {
  const { auth } = useAuth();
  const [data, setData] = useState<UserInfo>(auth as UserInfo);

  useEffect(() => {
    setData(auth as UserInfo)
  }, [auth]);
  return (
    <Modal
      open={open}
      onOk={() => onOk(data)}
      onCancel={onCancel}
      title="Customize Profile"
    >
      <Space
        style={{ width: "100%", paddingRight: "20px" }}
        direction="vertical"
      >
        <Row gutter={[16, 16]}>
          <Col span={4}></Col>
          <Col span={8} style={{ textAlign: "center", fontWeight: "bold" }}>
            Current
          </Col>
          <Col span={4}></Col>
          <Col span={8} style={{ textAlign: "center", fontWeight: "bold" }}>
            New
          </Col>
          {newData?.avatar_url && newData?.avatar_url !== auth?.avatar_url && (
            <>
              <Col span={4} style={{ textAlign: "right" }}>
                Avatar:
              </Col>
              <Col
                span={8}
                style={{ justifyContent: "center", display: "flex" }}
              >
                <Image
                  src={auth?.avatar_url || "user_placeholder.png"}
                  width={100}
                  height={100}
                />
              </Col>
              <Col
                span={4}
                style={{ justifyContent: "center", display: "flex" }}
              >
                <Switch
                  onChange={(checked) => {
                    setData({
                      ...data,
                      avatar_url: checked
                        ? newData?.avatar_url
                        : auth?.avatar_url,
                    });
                  }}
                />
              </Col>
              <Col
                span={8}
                style={{ justifyContent: "center", display: "flex" }}
              >
                <Image src={newData?.avatar_url} width={100} height={100} />
              </Col>
            </>
          )}
          {newData?.username && newData?.username !== auth?.username && (
            <>
              <Col span={4} style={{ textAlign: "right" }}>
                Name:
              </Col>
              <Col span={8}>{auth?.username}</Col>
              <Col
                span={4}
                style={{ justifyContent: "center", display: "flex" }}
              >
                <Switch
                  onChange={(checked) => {
                    setData({
                      ...data,
                      username: checked ? newData?.username : auth?.username,
                    });
                  }}
                />
              </Col>
              <Col span={8}>{newData?.username}</Col>
            </>
          )}
          {newData?.email && newData?.email !== auth?.email && (
            <>
              <Col span={4} style={{ textAlign: "right" }}>
                Email:
              </Col>
              <Col span={8}>{auth?.email}</Col>
              <Col
                span={4}
                style={{ justifyContent: "center", display: "flex" }}
              >
                <Switch
                  onChange={(checked) => {
                    setData({
                      ...data,
                      email: checked ? newData?.email : auth?.email,
                    });
                  }}
                />
              </Col>
              <Col span={8}>{newData?.email}</Col>
            </>
          )}
          {newData?.phone && newData?.phone !== auth?.phone && (
            <>
              <Col span={4} style={{ textAlign: "right" }}>
                Phone:
              </Col>
              <Col span={8}>{auth?.phone}</Col>
              <Col
                span={4}
                style={{ justifyContent: "center", display: "flex" }}
              >
                <Switch
                  onChange={(checked) => {
                    setData({
                      ...data,
                      phone: checked ? newData?.phone : auth?.phone,
                    });
                  }}
                />
              </Col>
              <Col span={8}>{newData?.phone}</Col>
            </>
          )}
          {newData?.bio && newData?.bio !== auth?.bio && (
            <>
              <Col span={4} style={{ textAlign: "right" }}>
                Bio:
              </Col>
              <Col span={8}>{auth?.bio}</Col>
              <Col
                span={4}
                style={{ justifyContent: "center", display: "flex" }}
              >
                <Switch
                  onChange={(checked) => {
                    setData({
                      ...data,
                      bio: checked ? newData?.bio : auth?.bio,
                    });
                  }}
                />
              </Col>
              <Col span={8}>{newData?.bio}</Col>
            </>
          )}
        </Row>
      </Space>
    </Modal>
  );
};

export default ProfileUpdateModal;
