import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Space } from "antd";
import { getAllDiscounts, deleteDiscount } from "../../api/discountService";
import DiscountForm from "./DiscountForm";
import { Discount } from "../../types/Discount";

const DiscountsList: React.FC = () => {
  const [discounts, setDiscounts] = useState<Discount[]>([]);
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedDiscountId, setSelectedDiscountId] = useState<number | null>(
    null
  );

  useEffect(() => {
    fetchDiscounts();
  }, []);

  const fetchDiscounts = async () => {
    setLoading(true);
    try {
      const { data } = await getAllDiscounts();
      setDiscounts(data);
    } catch (error) {
      console.error("Error fetching discounts:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id: number) => {
    try {
      await deleteDiscount(id);
      fetchDiscounts();
    } catch (error) {
      console.error("Error deleting discount:", error);
    }
  };

  const handleEdit = (id: number) => {
    setSelectedDiscountId(id);
    setModalVisible(true);
  };

  const handleSave = () => {
    setModalVisible(false);
    fetchDiscounts();
  };

  const columns = [
    { title: "ID", dataIndex: "discount_id", key: "discount_id", render: (_:any, __:any, index:number) => index + 1 },
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Description", dataIndex: "description", key: "description" },
    { title: "Percentage", dataIndex: "percentage", key: "percentage" },
    { title: "Start Date", dataIndex: "start_date", key: "start_date" },
    { title: "End Date", dataIndex: "end_date", key: "end_date" },
    {
      title: "Eligibility Criteria",
      dataIndex: "eligibility_criteria",
      key: "eligibility_criteria",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: any, record: Discount) => (
        <span style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          <Button type="primary" onClick={() => handleEdit(record.discount_id!)}>Edit</Button>
          <Button type="primary" danger onClick={() => handleDelete(record.discount_id!)}>
            Delete
          </Button>
        </span>
      ),
    },
  ];

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Button
        type="primary"
        onClick={() => {
          setModalVisible(true);
          setSelectedDiscountId(null)
        }}
        style={{ float: "right" }}
      >
        Create Discount +
      </Button>
      <Table
        dataSource={discounts}
        columns={columns}
        rowKey="discount_id"
        loading={loading}
      />
      <Modal
        title={selectedDiscountId ? "Edit Discount" : "Create Discount"}
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <DiscountForm
          discountId={selectedDiscountId ?? undefined}
          onSave={handleSave}
          discount={Array.isArray(discounts) ?  discounts.find(
            (discount) => discount.discount_id === selectedDiscountId
          ) : undefined}
        />
      </Modal>
    </Space>
  );
};

export default DiscountsList;
