import { Space } from "antd";
import { useParams } from "react-router-dom";
import ForumDetail from "../../Components/EventDetail/MessageDetail";
import MemberItem from "../../Components/Chat/MemberItem";
import { useContext, useEffect, useState } from "react";
import { useUserContext } from "../../Provider/UserContext";
import { SocketContext } from "../../Provider/SocketContext";
import { read_me, read_me_business } from "../../api/auth";
import { UserProfileProps } from "../../types/models";
import { SignupResponse } from "../../types/auth";
import { getFriends } from "../../api/api";
import styled from "styled-components";
import { useSearch } from "../../Provider/SearchContext";
const Panel = styled.div`
  background-color: #202a16;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: start;
  border-radius: 16px;
  padding: 40px;
  height: 100%;
`;

export interface Friend {
  userId: string;
  username: string;
}
const ChatPage = () => {
  const { searchText } = useSearch();
  const socket = useContext(SocketContext);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [members, setMembers] = useState<SignupResponse[]>([]);
  const [selectedMember, setSelectedMember] = useState(-1);
  const { userData, setUser } = useUserContext();

  const handleGetFriends = async () => {
    const response = await getFriends();
    if (response.data) {
      let members = response.data;
      if (searchText) {
        members = members.filter((member) =>
          member.username?.toLowerCase().includes(searchText.toLowerCase())
        );
      }
      setMembers(members);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const auth_type = localStorage.getItem("type");
    if (token) {
      if (auth_type === "user") {
        const fetch_data = async (token: string) => {
          const response = await read_me(token);
          if (response) {
            setUser(response as UserProfileProps);
          }
        };
        fetch_data(token);
      } else {
        const fetch_data = async (token: string) => {
          const response = await read_me_business(token);
          if (response) {
            setUser(response as UserProfileProps);
          }
        };
        fetch_data(token);
      }
    }
  }, []);

  const handleAddFriend = () => {
    setLoading(true);
    const newFriend = { username: id };
    socket.emit("addFriend", { id: userData?.user_id, friend: newFriend });

    const timeoutId = setTimeout(() => {
      if (loading) {
        setLoading(false);
      }
    }, 5000);

    const handleAddFriendResponse = (response: {
      success: boolean;
      data?: SignupResponse;
      message?: string;
    }) => {
      clearTimeout(timeoutId);
      setLoading(false);
      if (response.success) {
        console.log(response.data);
        if (response.data) setMembers([...members, response.data]);
      }
    };

    socket.on("addFriend:response", handleAddFriendResponse);

    return () => {
      socket.off("addFriend:response", handleAddFriendResponse);
    };
  };

  const handleSetInitialData = async () => {
    await handleGetFriends();
    if (id !== "me" && id !== undefined) {
      const user_id = parseInt(id);
      if (!members.some((member) => member.user_id === user_id))
        handleAddFriend();
      setSelectedMember(parseInt(id));
    }
  };

  useEffect(() => {
    handleSetInitialData();
  }, [id, searchText]);

  return (
    <Panel>
      <h1>Chats</h1>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flex: "1",
            flexDirection: "column",
            padding: "20px 30px",
          }}
        >
          <ForumDetail memberId={selectedMember} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "0px 5px",
            width: "400px",
            overflow: "auto",
          }}
        >
          {members.map((member, index) => (
            <MemberItem
              key={index}
              data={member}
              type="chat"
              selected={member?.user_id === selectedMember}
              onClick={() => setSelectedMember(member?.user_id!)}
            />
          ))}
        </div>
      </div>
    </Panel>
  );
};

export default ChatPage;
