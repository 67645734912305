import { Modal, Space } from "antd";

export interface ViewCustomersModalProps {
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
  data: string[] | undefined;
}

const ViewCustomersModal: React.FC<ViewCustomersModalProps> = ({
  open,
  onOk,
  onCancel,
  data,
}) => {
  return (
    <Modal open={open} onOk={onOk} onCancel={onCancel} title="Customers">
      <Space>
        {data && data.length ? (
          data.map((item, index) => <div key={index}>{item}</div>)
        ) : (
          <div style={{ minHeight: "280px" }}>There are no customers.</div>
        )}
      </Space>
    </Modal>
  );
};

export default ViewCustomersModal;
