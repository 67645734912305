import React from "react";
import styled from "styled-components";
import EventsBoard from "../../Components/Events/EventsBoard";

const EventsPanel = styled.div`
  display: flex;
  height: 100%;
  height: 100%;
`;

const EventsPage: React.FC = () => {
  return (
    <EventsPanel>
      <EventsBoard />
    </EventsPanel>
  );
};

export default EventsPage;
