import { Button, Card, Space, notification } from "antd";
import PlaceItem from "./PlaceItem";
import { useContext, useEffect, useRef, useState } from "react";
import useMapAccessToken from "../../hooks/useMapAccessToken";
import { SocketContext } from "../../Provider/SocketContext";
import { useAuth } from "../../Provider/AuthContext";
import { Loader } from "@googlemaps/js-api-loader";
import { calculateProximity, calculateRadius } from "../../utils/CalcHelpers";
import { useData } from "../../Provider/DataContext";
import { getRecommendEvents } from "../../api/api";
import { EventInfo } from "../../types/model";

export interface Place {
  name: string;
  place_id: string;
  rating?: number;
  geometry: google.maps.places.PlaceGeometry;
}

export interface Event {
  place: Place;
  name: string;
  location: string;
  description: string;
  score: number;
  friendRating?: number;
}

export interface LocationCardProps {
  type: "suggest" | "myself";
  distance: number;
  distanceType: "K" | "M";
  placeType:
    | "bar"
    | "restaurant"
    | "night_club"
    | "concert_hall"
    | "movie_theater"
    | "theater";
}

const LocationCard: React.FC<LocationCardProps> = ({
  type,
  distance,
  distanceType,
  placeType,
}) => {
  const { event, setEvent } = useData();
  const [api, contextHolder] = notification.useNotification();
  const mapContainer = useRef<HTMLDivElement>(document.createElement("div"));
  const socket = useContext(SocketContext);
  const [mapAccessToken, mapError] = useMapAccessToken(socket);
  const loader = useRef<Loader | null>(null);
  const [latitude, setLatitude] = useState<number>(0);
  const [longitude, setLongitude] = useState<number>(0);
  const searchBoxRef = useRef<HTMLInputElement>(null);
  const [markers, setMarkers] = useState<google.maps.Marker[]>([]);
  const [sunCircle, setSunCircle] = useState<google.maps.Circle | null>(null);
  const [places, setPlaces] = useState<google.maps.places.PlaceResult[]>([]);
  const [suggestEvents, setSuggestEvents] = useState<Event[]>([]);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [recommendEvents, setRecommendEvents] = useState<EventInfo[]>([]);
  const [loading, setLoading] = useState(false);
  const { auth_type } = useAuth();

  const __getRecommendEvents = async () => {
    try {
      const response = await getRecommendEvents();
      console.log("Friend: ", response.data);
      if (response.data) setRecommendEvents(response.data);
    } catch (error) {
      console.error("Failed to fetch friend events:", error);
    }
  };

  const getSuggestEvents = () => {
    const suggestEvents: Event[] = [];
    places.map((place) => {
      const index = recommendEvents.findIndex(
        (recommendEvent) => recommendEvent.location === place.place_id
      );

      let score = place.rating || 0;
      const eventDistance = place.geometry?.location
        ? calculateProximity(
            latitude,
            longitude,
            place.geometry.location.lat(),
            place.geometry.location.lng(),
            distanceType
          )
        : 0;

      if (eventDistance <= distance) {
        score += 5;
      }

      if (index !== -1) {
        suggestEvents.push({
          place: place as Place,
          location: place.place_id!,
          score: score + recommendEvents[index].rating!, // friend rating
          friendRating: recommendEvents[index].rating,
          name: recommendEvents[index].name!,
          description: recommendEvents[index].description!,
        });
      } else {
        suggestEvents.push({
          place: place as Place,
          location: place.place_id!,
          score: score,
          name: `Visit ${place.name}`,
          description: `Come and enjoy the wonderful experience at ${
            place.name
          }, located at ${place.place_id}. This place is known for its ${
            placeType || "unique atmosphere"
          }.`,
        });
      }
      return true;
    });
    suggestEvents.sort((a, b) => b.score - a.score);
    setSuggestEvents(suggestEvents);
  };

  useEffect(() => {
    if (type === "suggest") {
      getSuggestEvents();
    }
  }, [places]);

  const addPlaces = (
    newPlaces: google.maps.places.PlaceResult[],
    map: google.maps.Map
  ) => {
    const newMarkers: google.maps.Marker[] = [];

    for (const place of newPlaces) {
      if (place.geometry && place.geometry.location) {
        const marker = new google.maps.Marker({
          map,
          position: place.geometry.location,
          title: place.name,
        });
        newMarkers.push(marker);
      }
    }

    const sortedNewPlaces = newPlaces.sort((a, b) => {
      const distanceA =
        a && a.geometry && a.geometry.location
          ? calculateProximity(
              a.geometry.location.lat(),
              a.geometry.location.lng(),
              map.getCenter()!.lat(),
              map.getCenter()!.lng(),
              distanceType
            )
          : 0;

      const distanceB =
        b && b.geometry && b.geometry.location
          ? calculateProximity(
              b.geometry.location.lat(),
              b.geometry.location.lng(),
              map.getCenter()!.lat(),
              map.getCenter()!.lng(),
              distanceType
            )
          : 0;

      return distanceA - distanceB;
    });

    setMarkers((prevMarkers) => [...prevMarkers, ...newMarkers]);
    setPlaces((prevPlaces) => [...prevPlaces, ...sortedNewPlaces]);
  };

  const findNearbyPlaces = () => {
    if (!map) {
      return api.open({
        key: "error",
        type: "error",
        message: "Error",
        description: "Map not initialized. Can not find nearby places.",
      });
    }
    clearMarkers();
    setLoading(true);

    const center = map.getCenter();
    const radius = calculateRadius(map);
    const apiKey = mapAccessToken;

    if (center && radius && apiKey) {
      const pyrmont = new google.maps.LatLng(center.lat(), center.lng());
      const request = {
        location: pyrmont,
        radius: radius,
        type: placeType,
      };

      const sunCircleOptions = {
        strokeColor: "#c3fc49",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#c3fc49",
        fillOpacity: 0.35,
        map: map,
        center: pyrmont,
        radius: radius,
      };

      const cityCircle = new google.maps.Circle(sunCircleOptions);
      setSunCircle(cityCircle);

      const service = new google.maps.places.PlacesService(map);

      service.nearbySearch(request, (results, status, pagination) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          addPlaces(results || [], map);
          if (pagination && pagination.hasNextPage && places.length < 50) {
            pagination.nextPage();
          }
        } else {
          console.error("Error fetching nearby places:", status);
          api.open({
            key: "error",
            type: "error",
            message: "Error",
            description:
              status === "ZERO_RESULTS"
                ? "There are no places."
                : "Error fetching nearby places",
          });
        }
        setLoading(false);
      });
    } else {
      api.open({
        key: "error",
        type: "error",
        message: "Error",
        description: "Bounds and API key need to be initialized.",
      });
      setLoading(false);
    }
  };

  const getLocation = () => {
    setLoading(true);
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        api.open({
          key: "error",
          type: "error",
          message: "Error",
          description: mapError,
        });
      }
    );
  };

  const setEventPlace = (place_id: string) => {
    const new_event = { ...event, location: place_id };
    setEvent(new_event);
  };

  const clearMarkers = () => {
    markers.forEach((marker) => {
      marker.setMap(null);
    });

    if (sunCircle) {
      sunCircle.setMap(null);
      setSunCircle(null);
    }
    setMarkers([]);
    setPlaces([]);
  };

  useEffect(() => {
    getLocation();
    if (auth_type == "user") {
      __getRecommendEvents();
    }
  }, [auth_type]);

  useEffect(() => {
    if (mapAccessToken) {
      loader.current = new Loader({
        apiKey: mapAccessToken,
        version: "weekly",
        libraries: ["places"],
      });

      loader.current
        .importLibrary("core")
        .then(() => {
          const googleMap = new google.maps.Map(mapContainer.current!, {
            center: { lat: latitude, lng: longitude },
            zoom: 16,
          });

          const searchBox = new google.maps.places.SearchBox(
            searchBoxRef.current!
          );
          googleMap.controls[google.maps.ControlPosition.TOP_LEFT].push(
            searchBoxRef.current!
          );

          googleMap.addListener("bounds_changed", () => {
            searchBox.setBounds(
              googleMap.getBounds() as google.maps.LatLngBounds
            );
          });

          searchBox.addListener("places_changed", () => {
            const places = searchBox.getPlaces();
            console.log(places);
            if (places && places.length === 0) {
              return;
            }

            clearMarkers();
            const bounds = new google.maps.LatLngBounds();

            places!.forEach((place) => {
              if (!place.geometry || !place.geometry.location) {
                console.log("Returned place contains no geometry");
                return;
              }

              const marker = new google.maps.Marker({
                map: googleMap,
                title: place.name,
                position: place.geometry.location,
              });

              setMarkers((prevMarkers) => [...prevMarkers, marker]);

              if (place.geometry.viewport) {
                bounds.union(place.geometry.viewport);
              } else {
                bounds.extend(place.geometry.location);
              }
            });

            googleMap.fitBounds(bounds);
            setPlaces(places || []);
          });

          setMap(googleMap);
        })
        .catch((error) => {
          console.error("Failed to load Google Maps API", error);
        });
    } else if (mapError) {
      api.open({
        key: "error",
        type: "error",
        message: "Error",
        description: mapError,
      });
    }
  }, [mapAccessToken, mapError, latitude, longitude]);

  return (
    <Card
      title={type === "suggest" ? "Suggested Events" : "Find Nearby Places"}
      style={{ position: "relative" }}
    >
      <Button
        onClick={() => findNearbyPlaces()}
        style={{
          position: "absolute",
          top: "12px",
          right: "25px",
        }}
        type="primary"
      >
        {type === "myself" ? "Find nearby places" : "Get suggest events"}
      </Button>
      {contextHolder}
      <Space style={{ position: "relative" }}>
        <input
          ref={searchBoxRef}
          type="text"
          placeholder="Search places"
          style={{
            boxSizing: "border-box",
            border: "1px solid transparent",
            width: "240px",
            height: "32px",
            marginTop: "27px",
            padding: "0 12px",
            borderRadius: "3px",
            boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
            fontSize: "14px",
            outline: "none",
            textOverflow: "ellipses",
            position: "absolute",
            top: "10px",
            left: "10px",
            zIndex: "5",
          }}
        />
        <div
          id="map"
          ref={mapContainer}
          style={{
            minWidth: "900px",
            minHeight: "500px",
            width: "100%",
            height: "100%",
          }}
        />
        {loading && <div>Loading...</div>}
        <Space
          direction="vertical"
          style={{
            overflowY: "auto",
            maxHeight: "520px",
            padding: "20px 10px",
            minWidth: "270px",
          }}
        >
          {type === "myself"
            ? places.map((place) => (
                <PlaceItem
                  key={place.place_id}
                  selected={place.place_id === event?.location}
                  name={place.name ?? ""}
                  rating={place.rating || "N/A"}
                  proximity={
                    (map &&
                      map.getCenter()?.lat() &&
                      map.getCenter()?.lng() &&
                      place.geometry?.location &&
                      `Kilometers: ${calculateProximity(
                        place.geometry.location.lat(),
                        place.geometry.location.lng(),
                        map.getCenter()!.lat(),
                        map.getCenter()!.lng(),
                        "K"
                      ).toFixed(2)} | Miles: ${calculateProximity(
                        place.geometry.location.lat(),
                        place.geometry.location.lng(),
                        map.getCenter()!.lat(),
                        map.getCenter()!.lng(),
                        "M"
                      ).toFixed(2)}`) ??
                    ""
                  }
                  onSelect={() => {
                    setEventPlace(place.place_id!);
                  }}
                />
              ))
            : suggestEvents.map((suggestEvent) => (
                <PlaceItem
                  key={suggestEvent.place.place_id}
                  selected={suggestEvent.place.place_id === event?.location}
                  name={suggestEvent.place.name}
                  rating={suggestEvent.place.rating || "N/A"}
                  proximity={
                    (map &&
                      map.getCenter()?.lat() &&
                      map.getCenter()?.lng() &&
                      suggestEvent.place.geometry?.location &&
                      `Kilometers: ${calculateProximity(
                        suggestEvent.place.geometry.location.lat(),
                        suggestEvent.place.geometry.location.lng(),
                        map.getCenter()!.lat(),
                        map.getCenter()!.lng(),
                        "K"
                      ).toFixed(2)} | Miles: ${calculateProximity(
                        suggestEvent.place.geometry.location.lat(),
                        suggestEvent.place.geometry.location.lng(),
                        map.getCenter()!.lat(),
                        map.getCenter()!.lng(),
                        "M"
                      ).toFixed(2)}`) ??
                    ""
                  }
                  friendRating={suggestEvent.friendRating}
                  onSelect={() => {
                    setEvent({
                      name: suggestEvent.name,
                      description: suggestEvent.description,
                      location: suggestEvent.location,
                    });
                  }}
                />
              ))}
        </Space>
      </Space>
    </Card>
  );
};

export default LocationCard;
