import { Button, Card, Popconfirm, Space } from "antd";
import { Forum } from "../../types/Forum";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useAuth } from "../../Provider/AuthContext";

export interface ForumItemProps {
  data: Forum;
  onClick: () => void;
  onUpdate: () => void;
  onDelete: () => void;
}

const ForumItem: React.FC<ForumItemProps> = ({
  data,
  onClick,
  onUpdate,
  onDelete,
}) => {
  const { auth } = useAuth();
  return (
    <Card
      onClick={onClick}
      hoverable
      title={data.title}
      extra={
        data.user_id == auth?.user_id ? (
          <Space>
            <Button
              key="edit"
              onClick={(event) => {
                event.stopPropagation();
                onUpdate();
              }}
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
            />
            <Popconfirm
              title="Delete the forum"
              description="Are you sure to delete this forum?"
              onConfirm={(event) => {
                event?.stopPropagation();
                onDelete();
              }}
              onCancel={(event) => {
                event?.stopPropagation();
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button
                danger
                type="primary"
                shape="circle"
                key="delete"
                onClick={(event) => {
                  event.stopPropagation();
                }}
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Space>
        ) : undefined
      }
    >
      {data.content}
    </Card>
  );
};

export default ForumItem;
