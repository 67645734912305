import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import Layout from "./Layout";
import Provider from "./Provider";
import Router from "./Routes";
import "./index.css";
import './assets/fonts/fonts.css';

const App: React.FC = () => {
  return (
    <React.StrictMode>
      <Provider>
        <BrowserRouter>
          <Layout>
            <Router />
          </Layout>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  );
};

const root = document.getElementById("root");
ReactDOM.render(<App />, root);
