import { FriendInfo } from "../../types/model";
import {
  Modal,
  Button,
  Divider,
  Card,
  Spin,
  Checkbox,
  Row,
  Col,
  Rate,
  Badge,
  Space,
} from "antd";
import moment from "moment";
import { useState, useEffect } from "react";
import { getFriendsList, recommendEvent } from "../../api/api";
import {
  getDiscountByLocation,
  createDiscountUsage,
} from "../../api/discountService";
import { Discount } from "../../types/Discount";
import DiscountPercentageItem from "../Discounts/DiscountPercentageItem";

const { Group } = Checkbox;
const { Ribbon } = Badge;

export interface EventCardProps {
  event_id: string;
  name: string;
  description: string;
  location: string;
  business_id?: string;
  rating?: number;
  begin_at: Date;
  end_at: Date;
  onClick: () => void;
}

const EventCard: React.FC<EventCardProps> = ({
  event_id,
  name,
  business_id,
  begin_at,
  end_at,
  description,
  location,
  rating,
  onClick,
}) => {
  const [open, setOpen] = useState(false);
  const [discountModalOpen, setDiscountModalOpen] = useState(false);
  const [friends, setFriends] = useState<Array<FriendInfo>>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [discounts, setDiscounts] = useState<Discount[]>([]);
  const [selectedDiscountType, setSelectedDiscountType] = useState<string>("");
  const [selectedDiscount, setSelectedDiscount] = useState(-1);

  const handleGetFriends = async () => {
    try {
      if (event_id) {
        setLoading(true);
        const response = await getFriendsList(event_id);
        console.log("FriendsList:", response);
        if (response.data) setFriends(response.data);
      }
    } catch (error) {
      console.error("Failed to fetch events:", error);
    } finally {
      setLoading(false);
    }
  };

  const getDiscounts = async () => {
    const { data } = await getDiscountByLocation(location);
    if (Array.isArray(data)) {
      setDiscounts(data);
    }
  };

  useEffect(() => {
    getDiscounts();
  }, []);

  const discountFirst = discounts.find(
    (discount) => discount.eligibility_criteria === "first"
  );
  const renderResult = (
    <>
      <div
        onClick={onClick}
        style={{
          minWidth: "500px",
          padding: "30px 38px",
          display: "flex",
          flexDirection: "column",
          gap: "28px",
          borderRadius: "20px",
          height: "100%",
          color: "white",
          backgroundColor: "#202a16",
          cursor: "pointer",
        }}
      >
        {/* <div style={{ borderRadius: "14px" }}>Image here...</div> */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "60px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
              textAlign: "center",
              color: "#BEC2BA",
            }}
          >
            <div style={{ fontSize: "16px" }}>
              {moment(begin_at).format("ddd").toUpperCase()}
            </div>
            <div style={{ fontSize: "36px" }}>
              {moment(begin_at).format("DD")}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "6px",
            }}
          >
            <div style={{ fontSize: "20px", color: "white" }}>{name}</div>
            <div style={{ fontSize: "16px", color: "#BEC2BA" }}>
              {moment(begin_at).format("ddd DD MMM - h:mm A ")}-&nbsp;{location}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            position: "absolute",
            bottom: "20px",
            right: "20px",
            flexDirection: "row",
            gap: "10px",
          }}
        >
          {discounts.length > 0 && discountFirst !== undefined && (
            <Button
              type="dashed"
              style={{ color: "#eb2f96", borderColor: "#eb2f96" }}
              onClick={(event) => {
                event.stopPropagation();
                setSelectedDiscountType("first");
                setDiscountModalOpen(true);
              }}
            >
              {discountFirst?.percentage + "%"}
            </Button>
          )}
          {discounts.length > 0 &&
            discounts.findIndex((discount) =>
              discount.eligibility_criteria?.startsWith("group")
            ) !== -1 && (
              <Button
                type="dashed"
                danger
                onClick={(event) => {
                  event.stopPropagation();
                  setSelectedDiscountType("group");
                  setDiscountModalOpen(true);
                }}
              >
                {discounts.find((discount) =>
                  discount.eligibility_criteria?.startsWith("group")
                )?.percentage + "%"}
              </Button>
            )}
        </div>
        {/* <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            width: "auto",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div style={{ wordWrap: "break-word" }}>{description}</div>
          <div>{location}</div>
          <div>
            {moment(begin_at).format("MM/DD/YYYY HH:mm")}~
            {moment(end_at).format("MM/DD/YYYY HH:mm")}
          </div>
          
        </div> */}
        <Row gutter={[12, 12]}>
          {/* <Col span={6}>
            <Button style={{ width: "100%" }}>Attending</Button>
          </Col>
          <Col span={6}>
            <Button style={{ width: "100%" }}>Not Attending</Button>
          </Col>
          <Col span={6}>
            <Button style={{ width: "100%" }}>Share</Button>
          </Col> */}
          <Col span={6} offset={18}>
            <Button
              style={{ width: "100%" }}
              type="primary"
              onClick={(event) => {
                event.stopPropagation();
                setOpen(true);
                handleGetFriends();
              }}
            >
              Recommend
            </Button>
          </Col>
        </Row>
      </div>
      <Modal
        open={open}
        onCancel={(event) => {
          event.stopPropagation();
          setOpen(false);
        }}
        onOk={async (event) => {
          event.stopPropagation();
          if (selected.length) {
            for (let userId of selected) {
              await recommendEvent({
                eventId: event_id!,
                friendId: userId,
              });
            }
          }
          await handleGetFriends();
          setOpen(false);
        }}
      >
        <div onClick={(event) => event.stopPropagation()}>
          <h2>Recommend Event</h2>
          <Divider style={{ margin: "10px 0px" }} />
          <Spin spinning={loading}>
            <Group
              style={{ width: "100%" }}
              onChange={(checkedValues) => {
                setSelected(checkedValues);
              }}
            >
              <Row
                style={{ minHeight: "200px", width: "100%" }}
                gutter={[8, 8]}
              >
                {friends.length ? (
                  friends.map((friend) => (
                    <Col span={6}>
                      <Checkbox
                        key={friend.userId}
                        value={friend.userId}
                        style={{ placeSelf: "center" }}
                      >
                        {friend.username}
                      </Checkbox>
                    </Col>
                  ))
                ) : (
                  <div>No users found.</div>
                )}
              </Row>
            </Group>
            <Divider style={{ margin: "10px 0px" }} />
          </Spin>
        </div>
      </Modal>
    </>
  );
  if (discounts.length > 0) {
    return (
      <>
        <Ribbon
          text={discounts[0].percentage + "%"}
          color={discounts[0].eligibility_criteria === "first" ? "pink" : "red"}
        >
          {renderResult}
        </Ribbon>
        <Modal
          width={selectedDiscountType === "first" ? 400 : 600}
          open={discountModalOpen}
          onCancel={(event) => {
            event.stopPropagation();
            setDiscountModalOpen(false);
          }}
          onOk={(event) => {
            event.stopPropagation();
            if (selectedDiscountType === "first") {
              createDiscountUsage(discountFirst?.discount_id!, event_id);
            } else {
              createDiscountUsage(
                discounts.filter((discount) =>
                  discount.eligibility_criteria?.startsWith("group")
                )[selectedDiscount].discount_id!,
                event_id
              );
            }
            setDiscountModalOpen(false);
          }}
          title={
            selectedDiscountType === "first"
              ? discountFirst?.name
              : "Group Discounts"
          }
        >
          {selectedDiscountType === "first" ? (
            <div
              style={{ flexDirection: "column", display: "flex", gap: "10px" }}
            >
              <DiscountPercentageItem
                percentage={discountFirst?.percentage!}
                type="first"
              />
              {discountFirst?.end_date && (
                <div>
                  Until {moment(discountFirst?.end_date).format("MM/DD/YYYY")}
                </div>
              )}
              <div>{discountFirst?.description}</div>
            </div>
          ) : (
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                gap: "10px",
                overflowY: "auto",
              }}
            >
              {discounts
                .filter((discount) =>
                  discount.eligibility_criteria?.startsWith("group")
                )
                .map((discount, index) => (
                  <Card
                    title={discount.name}
                    key={index}
                    hoverable
                    onClick={() => setSelectedDiscount(index)}
                    style={{
                      border:
                        index === selectedDiscount
                          ? "1px solid black"
                          : "1px solid #ddd",
                    }}
                  >
                    <DiscountPercentageItem
                      percentage={discount.percentage}
                      type="group"
                    />
                    <div
                      style={{
                        textAlign: "center",
                        marginTop: "5px",
                        marginBottom: "10px",
                      }}
                    >
                      {discount.eligibility_criteria?.split(" ")[1]}
                    </div>
                    {discount.end_date && (
                      <div
                        style={{
                          textAlign: "center",
                          textDecoration: "underline",
                        }}
                      >
                        Until {moment(discount.end_date).format("MM/DD/YYYY")}
                      </div>
                    )}
                    <div>{discount.description}</div>
                  </Card>
                ))}
            </div>
          )}
        </Modal>
      </>
    );
  }
  return renderResult;
};
export default EventCard;
