
import styled from "styled-components";
const Panel = styled.div`
  height: 100%;
  padding: 40px;
  background-color: #202A16;
  border-radius: 16px;
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: start;
`;

const SettingsPage = () => {
  return <Panel>
    <h1>Settings</h1>
  </Panel>;
};

export default SettingsPage;
