import { Button } from "antd";
import React from "react";
import { NavLink } from "react-router-dom";

export interface NavbarItemProps {
  pathname: string;
  content: any;
  icon: any;
  badge: number;
}

const NavbarItem: React.FC<NavbarItemProps> = ({
  pathname,
  content,
  icon,
  badge,
}) => {
  return (
    <NavLink to={pathname} className="nav-item">
      <div className="item">
        {typeof icon === "string" ? (
          <img src={icon} width={24} height={24} alt="icon" />
        ) : (
          icon
        )}
        <div>{content}</div>
      </div>
      {badge !== 0 && <div className="badge">{badge}</div>}
    </NavLink>
  );
};

export default NavbarItem;
