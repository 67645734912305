import React, { useState } from "react";
import {
  FormContainer,
  FormGroup,
  Input,
  Label,
  Title,
  Loginline,
  Button
} from "../Components/Components.styles";
import { Link, Navigate } from "react-router-dom"; // Change import to use Routes and Route
import Alert from "../types/Alert";
import { useForm, SubmitHandler } from "react-hook-form";
import { ContinueLabel, ContinueWrapper } from "./Pages.styles";
import { useAuth } from "../Provider/AuthContext";
import { signin, signin_business } from "../api/auth";
import { SigninData } from "../types/auth";
import SocailButtons from "../Components/SocialButtons";
// import { Button } from "antd";

const Login: React.FC<{ type: "user" | "business" }> = ({ type = "user" }) => {
  const [statusText, setStatusText] = useState<string>("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SigninData>();
  const { setAuth, setAuthBusiness, auth, auth_business, setAuthType } =
    useAuth();
  const onSubmit: SubmitHandler<SigninData> = async (data) => {
    // Here you can perform your login logic
    setStatusText("Logging in...");
    if (type === "user") {
      const response = await signin(data);
      setAuth(response);
      setAuthType("user");
    } else {
      const response = await signin_business(data);
      setAuthType("business");
      setAuthBusiness(response);
    }
  };

  return (
    <>
      {type === "user" ? (
        <FormContainer>
          <Title>Login</Title>
          {auth?.access_token ? <Navigate to={"/"} /> : ""}
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Label htmlFor="email">Email or Phone:</Label>
              <Input
                id="email"
                type="text"
                {...register("email", {
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: "Invalid email address",
                  },
                })}
              />
              {errors.email && <span>{errors.email.message}</span>}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="password">Password:</Label>
              <Input
                id="password"
                type="password"
                {...register("password", {
                  required: "Password is required",
                  minLength: {
                    value: 6,
                    message: "Password must be at least 6 characters",
                  },
                })}
              />
              {errors.password && <span>{errors.password.message}</span>}
            </FormGroup>
            <Button
              // type="primary"
              type="submit"
              style={{ marginTop: "15px", width: "100%" }}
            >
              Login
            </Button>
          </form>
          <p style={{ textAlign: "center" }}>
            Do you need a Business Login?{" "}
            <Link to="/business-login" style={{ color: "gray" }}>
              here
            </Link>
            .
          </p>
          <p style={{ textAlign: "center" }}>
            Don't have an account?{" "}
            <Link to="/register" style={{ color: "gray" }}>
              Register here
            </Link>
            .
          </p>
          <ContinueWrapper>
            <Loginline />
            <ContinueLabel>Or CONTINUE WITH</ContinueLabel>
          </ContinueWrapper>
          <div style={{ margin: "-5px" }}>
            <SocailButtons />
          </div>
          {statusText && <Alert>{statusText}</Alert>}
        </FormContainer>
      ) : (
        <FormContainer>
          <Title>Business Login</Title>
          {auth_business?.access_token ? <Navigate to={"/"} /> : ""}
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Label htmlFor="email">Email or Phone:</Label>
              <Input
                id="email"
                type="text"
                {...register("email", {
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: "Invalid email address",
                  },
                })}
              />
              {errors.email && <span>{errors.email.message}</span>}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="password">Password:</Label>
              <Input
                id="password"
                type="password"
                {...register("password", {
                  required: "Password is required",
                  minLength: {
                    value: 6,
                    message: "Password must be at least 6 characters",
                  },
                })}
              />
              {errors.password && <span>{errors.password.message}</span>}
            </FormGroup>
            <Button
              type="submit"
              // type="primary"
              style={{ marginTop: "15px", width: "100%" }}
            >
              Login
            </Button>
          </form>
          <p style={{ textAlign: "center" }}>
            Do you need a Individual Login?{" "}
            <Link to="/login" style={{ color: "gray" }}>
              here
            </Link>
            .
          </p>
          <p style={{ textAlign: "center" }}>
            Don't have an account?{" "}
            <Link to="/business-register" style={{ color: "gray" }}>
              Register here
            </Link>
            .
          </p>
          {statusText && <Alert>{statusText}</Alert>}
        </FormContainer>
      )}
    </>
  );
};

export default Login;
