import styled from "styled-components";
export const Text_large_gray = styled.div`
font-size: 20px;
font-weight: 700px;
color: #bcbcbc;
`
export const Text_base_gray = styled.div`
font-size: 16px;
font-weight: 500px;
color: #bcbcbc;
`
export const Text_small_gray = styled.div`
font-size: 14px;
font-weight: 400px;
color: #bcbcbc;
`
export const FormContainer = styled.div`
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #202A16;
  max-width: 400px;
`;

export const Title = styled.h2`
  text-align: center;
`;

export const FormGroup = styled.div`
  font-size: 12px;
  flex:1;
  display: flex;
  flex-direction: column;
  gap: 3px;
  margin-bottom: 15px;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
`;

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

export const Button = styled.button<{ disabled?: boolean }>`
  width: 100%;
  padding: 10px;
  background-color: #8ca870;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font: menu;
  font-size: 16px;
  &:hover {
    background-color: #2a62d7;
  }
    cursor: pointer;
  ${(props) =>
        props.disabled &&
    `
      background-color: rgba(0, 0, 0, 0.1);
      cursor: not-allowed;
    `}
`;

export const Line = styled.div`
top: 8px;
border: 0;
width: 100%;
border-top: 1px solid #ccc;
`
export const Loginline = styled.div`
position: absolute;
top: 8px;
index: 
width: 100%;
border: 0 ;
border-top: 1px solid #ccc;
`
export const Row = styled.div`
display: flex;
gap: 30px;
`

export const PostContainer = styled.div`
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #202A16;
`;

export const Header_large_theme = styled.h1`
  font-size: 32px;
  font-weight: 700;
  color: #8ca870;
`;

export const Header_medium_theme = styled.h2`
  font-size: 24px;
  font-weight: 600;
  color: #b0c4a1;
`;

export const Header_small_theme = styled.h3`
  font-size: 18px;
  font-weight: 500;
  color: #dcb5b4;
`;
