import { Input } from "antd";
const { TextArea } = Input;

export interface ForumEditFormProps {
  title: string;
  content: string;
  setTitle: (title: string) => void;
  setContent: (title: string) => void;
}

const ForumEditForm: React.FC<ForumEditFormProps> = ({
  title,
  content,
  setTitle,
  setContent,
}) => {
  return (
    <>
      <Input
        style={{ marginBottom: "10px" }}
        placeholder="Input forum title here..."
        value={title}
        onChange={(event) => setTitle(event.target.value)}
      />
      <TextArea
        placeholder="Input the forum content here..."
        rows={7}
        value={content}
        onChange={(event) => setContent(event.target.value)}
      />
    </>
  );
};

export default ForumEditForm;
