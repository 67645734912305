import { useCallback, useContext, useEffect, useRef, useState } from "react";
import useMapAccessToken from "../../hooks/useMapAccessToken";
import { SocketContext } from "../../Provider/SocketContext";
import { Loader } from "@googlemaps/js-api-loader";
import { notification } from "antd";

const Location = ({
  location,
  setLocation,
}: {
  location: string | undefined;
  setLocation: (location: string) => void;
}) => {
  const [api, contextHolder] = notification.useNotification();
  const mapContainer = useRef<HTMLDivElement>(document.createElement("div"));
  const socket = useContext(SocketContext);
  const [mapAccessToken, mapError] = useMapAccessToken(socket);
  const loader = useRef<Loader | null>(null);
  const [latitude, setLatitude] = useState<number>(0);
  const [longitude, setLongitude] = useState<number>(0);
  const searchBoxRef = useRef<HTMLInputElement>(null);
  const [markers, setMarkers] = useState<google.maps.Marker[]>([]);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [loading, setLoading] = useState(false);
  const [place, setPlace] = useState<
    google.maps.places.PlaceResult | undefined
  >(undefined);

  const getLocation = useCallback(() => {
    setLoading(true);
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        api.open({
          key: "error",
          type: "error",
          message: "Error",
          description: mapError,
        });
      }
    );
  }, [api, mapError]);

  const clearMarkers = useCallback(() => {
    markers.forEach((marker) => {
      marker.setMap(null);
    });
    setMarkers([]);
  }, [markers]);

  const getCoordinatesFromPlaceId = useCallback((placeId: string) => {
    if (!mapAccessToken || !map) return;
    try {
      setLoading(true);
      const service = new google.maps.places.PlacesService(
        mapContainer.current
      );

      service.getDetails({ placeId }, (result, status) => {
        if (
          status === google.maps.places.PlacesServiceStatus.OK &&
          result?.geometry?.location
        ) {
          const location = result.geometry.location;

          setLatitude(location.lat());
          setLongitude(location.lng());
          setPlace(result);
          map.setCenter(location);
          clearMarkers();

          const marker = new google.maps.Marker({
            map: map,
            title: result.name,
            position: location,
          });

          setMarkers((prevMarkers) => [...prevMarkers, marker]);
        } else {
          console.error("Failed to get place details", status);
        }
      });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [clearMarkers, map, mapAccessToken]);

  useEffect(() => {
    if (mapAccessToken) {
      loader.current = new Loader({
        apiKey: mapAccessToken,
        version: "weekly",
        libraries: ["places"],
      });

      loader.current
        .importLibrary("core")
        .then(() => {
          const googleMap = new google.maps.Map(mapContainer.current!, {
            center: { lat: latitude, lng: longitude },
            zoom: 16,
          });

          const searchBox = new google.maps.places.SearchBox(
            searchBoxRef.current!
          );
          googleMap.controls[google.maps.ControlPosition.TOP_LEFT].push(
            searchBoxRef.current!
          );

          googleMap.addListener("bounds_changed", () => {
            searchBox.setBounds(
              googleMap.getBounds() as google.maps.LatLngBounds
            );
          });

          searchBox.addListener("places_changed", () => {
            const places = searchBox.getPlaces();
            if (places && places.length === 0) {
              return;
            }

            clearMarkers();
            const bounds = new google.maps.LatLngBounds();

            places!.forEach((place) => {
              if (!place.geometry || !place.geometry.location) {
                console.log("Returned place contains no geometry");
                return;
              }

              const marker = new google.maps.Marker({
                map: googleMap,
                title: place.name,
                position: place.geometry.location,
              });

              setMarkers((prevMarkers) => [...prevMarkers, marker]);

              if (place.geometry.viewport) {
                bounds.union(place.geometry.viewport);
              } else {
                bounds.extend(place.geometry.location);
              }
            });

            googleMap.fitBounds(bounds);
            if (Array.isArray(places) && places.length > 0) {
              setPlace(places[0]);
              setLocation(places[0].place_id!);
            }
          });

          setMap(googleMap);
        })
        .catch((error) => {
          console.error("Failed to load Google Maps API", error);
        });
    } else if (mapError) {
      api.open({
        key: "error",
        type: "error",
        message: "Error",
        description: mapError,
      });
    }
  }, [mapAccessToken, mapError, latitude, longitude, api, clearMarkers, setLocation]);

  useEffect(() => {
    if (location) {
      getCoordinatesFromPlaceId(location);
    } else {
      getLocation();
    }
  }, [location, mapAccessToken, map, getCoordinatesFromPlaceId, getLocation]);

  return (
    <div style={{ position: "relative" }}>
      <input
        ref={searchBoxRef}
        type="text"
        placeholder="Search places"
        style={{
          boxSizing: "border-box",
          border: "1px solid transparent",
          width: "240px",
          height: "32px",
          marginTop: "27px",
          padding: "0 12px",
          borderRadius: "3px",
          boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
          fontSize: "14px",
          outline: "none",
          textOverflow: "ellipsis",
          position: "absolute",
          top: "10px",
          left: "10px",
          zIndex: "5",
        }}
      />
      <div
        id="map"
        ref={mapContainer}
        style={{
          minWidth: "400px",
          minHeight: "500px",
          width: "100%",
          height: "100%",
        }}
      />
      <div>{place?.place_id}</div>
      {loading && <div>Loading...</div>}
      {contextHolder}
    </div>
  );
};

export default Location;