// src/api/auth.ts
import axios from "axios";
import { Post } from "../types/model";
import {
  SignupData,
  SignupResponse,
  SigninData,
  AvatarResponse,
  SignupResponseBusiness,
  EventsResponse,
  EventCreate,
  EventResponse,
  FriendResponse,
} from "../types/auth";
import { UserInfos, UserInfo, BusinessInfos, EventInfo, User } from "../types/model";
const API_URL = process.env.REACT_APP_API_URL || "https://api.nook.today";
const getToken = () => localStorage.getItem("token");
export const updateUser = async (user: UserInfos): Promise<SignupResponse> => {
  const response = await axios.post(`${API_URL}/api/me/update`, user, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};
export const uploadAvatar = async (file: FormData): Promise<AvatarResponse> => {
  const response = await axios.post(`${API_URL}/api/me/avatar`, file, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

///business
export const updateBusiness = async (
  business: BusinessInfos
): Promise<SignupResponseBusiness> => {
  const response = await axios.post(
    `${API_URL}/api/business/update`,
    business,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};

export const uploadBusinessAvatar = async (
  file: FormData
): Promise<AvatarResponse> => {
  const response = await axios.post(`${API_URL}/api/business/avatar`, file, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};
//event
export const getAllEvent = async (
  auth_type: "user" | "business"
): Promise<EventsResponse> => {
  const response = await axios.get(`${API_URL}/api/event/all/${auth_type}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const getEvent = async (event_id: any): Promise<EventResponse> => {
  const response = await axios.get(`${API_URL}/api/event/${event_id}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const createEvent = async (
  data: EventCreate
): Promise<EventResponse> => {
  const response = await axios.post(`${API_URL}/api/event/create`, data, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};
export const updateEvent = async (data: EventInfo): Promise<EventResponse> => {
  const response = await axios.put(`${API_URL}/api/event/update`, data, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const getRecommendEvents = async (): Promise<EventsResponse> => {
  const response = await axios.get(`${API_URL}/api/event/recommend`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const getFriendsList = async (
  eventId: string
): Promise<FriendResponse> => {
  const response = await axios.get(`${API_URL}/api/event/friend/${eventId}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const recommendEvent = async (data: {
  eventId: string;
  friendId: string;
}): Promise<boolean> => {
  const response = await axios.post(`${API_URL}/api/event/recommend`, data, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const setEventRate = async (data: {
  eventId: string;
  rating: number;
}): Promise<boolean> => {
  const response = await axios.post(`${API_URL}/api/event/review`, data, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const getEventMembers = async (
  event_id: string
): Promise<{
  data?: Array<UserInfos>;
  error_message?: string;
}> => {
  const response = await axios.get(`${API_URL}/api/event/members/${event_id}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const getEventAttendee = async (
  event_id: string
): Promise<{
  data?: boolean;
  error_message?: string;
}> => {
  const response = await axios.get(
    `${API_URL}/api/event/attendee/${event_id}`,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};

export const addEventAttendee = async (
  event_id: string
): Promise<{
  data?: boolean;
  error_message?: string;
}> => {
  const response = await axios.post(
    `${API_URL}/api/event/attendee`,
    {
      event_id,
    },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};

export const getFriends = async (): Promise<{
  data?: SignupResponse[];
  error_message?: string;
}> => {
  const response = await axios.get(`${API_URL}/api/me/friends`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const getFriendsPosts = async (): Promise<{
  data?: Post[];
  error_message?: string;
}> => {
  try {
    const response = await axios.get(`${API_URL}/api/post/friends_posts`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });
    return { data: response.data };
  } catch (error) {
    console.error("Error fetching friends' posts:", error);
    return { error_message: "Failed to fetch friends' posts" };
  }
};

export const findFriends = async (
  searchText: string
): Promise<{
  data?: SignupResponse[];
  error_message?: string;
}> => {
  const response = await axios.get(
    `${API_URL}/api/me/find-friend/${searchText}`,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};

export const getFriend = async (
  user_id: string
): Promise<{
  data?: SignupResponse;
  error_message?: string;
}> => {
  const response = await axios.get(`${API_URL}/api/me/friend/${user_id}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const deleteUserFromEvent = async (
  event_id: string,
  user_id: number
): Promise<{
  data?: boolean;
  error_message?: string;
}> => {
  const response = await axios.delete(
    `${API_URL}/api/event/attendee/${event_id}/${user_id}`,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};

export const addFriend = async (
  user_id: number
): Promise<{
  data?: boolean;
  error_message?: string;
}> => {
  const response = await axios.get(`${API_URL}/api/me/add-friend/${user_id}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const getUpcomingEvents = async (): Promise<{
  data?: EventInfo[];
  error_message?: string;
}> => {
  const response = await axios.get(`${API_URL}/api/event/upcoming`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const getMatchEvents = async (): Promise<{
  data?: EventInfo[];
  error_message?: string;
}> => {
  const response = await axios.get(`${API_URL}/api/event/matching`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const getMatchMembers = async (): Promise<{
  data?: User[];
  error_message?: string;
}> => {
  const response = await axios.get(`${API_URL}/api/me/matching`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const getRecentConnections = async (): Promise<{
  data?: UserInfo[];
  error_message?: string;
}> => {
  const response = await axios.get(`${API_URL}/api/me/recent-connections`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};
