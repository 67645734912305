import React from 'react';
import styled from 'styled-components';

const PostItemCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: #202A16;
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 16px;
  border: 1px solid #EFBF04;
`;

const PostHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const Avatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2px solid #EFBF04;
`;

const Username = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #EFBF04;
`;

const Timestamp = styled.div`
  font-size: 12px;
  color: #aaa;
`;

const PostContent = styled.div`
  font-size: 14px;
  color: white;
  margin-top: 8px;
`;

export interface PostItemProps {
  content: string;
  username: string;
  createdAt: string;
  avatarUrl?: string;
}

const PostItem: React.FC<PostItemProps> = ({ content, username, createdAt, avatarUrl }) => {
  const API_URL = process.env.REACT_APP_API_URL || "https://api.nook.today";
  const avatarSrc = avatarUrl ? `${API_URL}/uploads/${avatarUrl}` : "user_placeholder.png";

  return (
    <PostItemCard>
      <PostHeader>
        <Avatar src={avatarSrc} alt={username} />
        <div>
          <Username>{username}</Username>
          <Timestamp>{createdAt}</Timestamp>
        </div>
      </PostHeader>
      <PostContent>{content}</PostContent>
    </PostItemCard>
  );
};

export default PostItem;
