import axios from "axios";
import {
  DeleteResponse,
  Ticket,
  TicketResponse,
  TicketsResponse,
} from "../types/Ticket";

const API_URL = `${process.env.REACT_APP_API_URL || "https://api.nook.today"}/api/ticket`;

const getToken = () => localStorage.getItem("token");

export const getTickets = async (
  event_id: string,
  auth_type: string
): Promise<TicketsResponse> => {
  const response = await axios.get(`${API_URL}/all/${event_id}/${auth_type}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const createTicket = async (ticket: Ticket): Promise<TicketResponse> => {
  const response = await axios.post(`${API_URL}/create`, ticket, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const updateTicket = async (
  ticket_id: number,
  ticket: Ticket
): Promise<TicketResponse> => {
  const response = await axios.put(`${API_URL}/${ticket_id}`, ticket, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const deleteTicket = async (
  ticket_id: number
): Promise<DeleteResponse> => {
  const response = await axios.delete(`${API_URL}/${ticket_id}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const getQrCode = async (ticket_id: number): Promise<any> => {
  const response = await axios.get(`${API_URL}/generate/${ticket_id}`, {
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });
  return URL.createObjectURL(response.data);
};

export const createPayment = async (
  ticket_id: number,
  quantity: number
): Promise<any> => {
  const response = await axios.post(
    `${API_URL}/payment/create`,
    {
      ticket_id,
      quantity,
    },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};

export const refundPayment = async (paymentIntentId: string): Promise<any> => {
  const response = await axios.post(
    `${API_URL}/payment/refund`,
    { payment_intent_id: paymentIntentId },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};

export const addPayment = async (
  ticket_id: number,
  payment_intent_id: string
): Promise<any> => {
  const response = await axios.post(
    `${API_URL}/payment/add`,
    {
      ticket_id,
      payment_intent_id,
    },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};
