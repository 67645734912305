import { Modal } from "antd";

export interface PaymentRefundComfirmModalProps {
  onOk: () => void;
  onCancel: () => void;
  open: boolean;
}

const PaymentRefundComfirmModal: React.FC<PaymentRefundComfirmModalProps> = ({
  open,
  onOk,
  onCancel,
}) => {
  return (
    <Modal title="Really" open={open} onOk={onOk} onCancel={onCancel}>
      Do you really want to refund this ticket?
    </Modal>
  );
};

export default PaymentRefundComfirmModal;
