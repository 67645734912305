import React from 'react';
import styled from 'styled-components';

const AlertContainer = styled.div`
  padding: 10px 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
`;

interface AlertProps {
  children: React.ReactNode; 
}

const Alert: React.FC<AlertProps> = ({ children }) => {
  return (
    <AlertContainer>
      {children}
    </AlertContainer>
  );
};

export default Alert;
