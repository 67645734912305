import { NavLink } from "react-router-dom";
import HomeIcon from "../../assets/nook_app.png";

const HomeItem = () => {
  return (
    <NavLink to="/" className="home-item">
      <img
        src={HomeIcon}
        width="40px"
        height="40px"
        alt="home"
        style={{ borderRadius: "3px" }}
      />
      <div style={{ width: "104px", alignItems: "center" }}>Nook</div>
    </NavLink>
  );
};

export default HomeItem;
