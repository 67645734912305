import { DatePicker, Divider, Input, Modal, Row, Space } from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;
export interface EventModalProps {
  open: boolean;
  name: string;
  location: string;
  description: string;
  begin_at: Date | undefined;
  end_at: Date | undefined;
  onOk: () => void;
  onCancel: () => void;
  onChangeName: (name: string) => void;
  onChangeDescription: (description: string) => void;
  onBeginAt: (date: Date) => void;
  onEndAt: (date: Date) => void;
}

const EventModal: React.FC<EventModalProps> = ({
  open,
  name,
  location,
  description,
  begin_at,
  end_at,
  onOk,
  onCancel,
  onChangeName,
  onChangeDescription,
  onBeginAt,
  onEndAt
}) => {
  return (
    <Modal open={open} onOk={onOk} onCancel={onCancel} title="Create Event">
      <Divider style={{ margin: "10px 0px" }} />
      <Space direction="vertical" style={{ width: "100%" }}>
        <Input
          name="name"
          placeholder="Input the event name here..."
          value={name}
          onChange={(e) => {
            onChangeName(e.target.value);
          }}
          style={{ width: "90%" }}
        />
        <Row>Place: {location}</Row>
        <RangePicker
          showTime
          format="YYYY-MM-DD HH:mm"
          value={[dayjs(begin_at), dayjs(end_at)]}
          onChange={(
            dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null
          ) => {
            if(dates) {
              dates[0] && onBeginAt(dates[0]?.toDate())
              dates[1] && onEndAt(dates[1]?.toDate())
            }
          }}
        />
        <TextArea
          name="description"
          placeholder="Input the event description here..."
          rows={7}
          value={description}
          onChange={(e) => {
            onChangeDescription(e.target.value);
          }}
        />
      </Space>
      <Divider style={{ margin: "10px 0px" }} />
    </Modal>
  );
};

export default EventModal;
