import React, { useEffect, useState } from "react";
import { Form, Input, Button, InputNumber, DatePicker, Select } from "antd";
import { createDiscount, updateDiscount } from "../../api/discountService";
import { Discount } from "../../types/Discount";
import dayjs from "dayjs";

const { Option } = Select;

interface DiscountFormProps {
  discountId?: number;
  onSave: () => void;
  discount?: Discount;
}

const DiscountForm: React.FC<DiscountFormProps> = ({
  discountId,
  onSave,
  discount,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [discountType, setDiscountType] = useState("first");
  const [memberMin, setMemberMin] = useState(0);
  const [memberMax, setMemberMax] = useState(0);

  useEffect(() => {
    if (discount) {
      const transformedDiscount = {
        ...discount,
        start_date: discount.start_date ? dayjs(discount.start_date, "MM/DD/YYYY") : null,
        end_date: discount.end_date ? dayjs(discount.end_date, "MM/DD/YYYY") : null,
        eligibility_criteria: discountType.startsWith("group")
          ? "group"
          : "first",
      };
      if(discountType.startsWith("group")) {
        const [min, max] = discountType.split("~")
        setMemberMin(parseInt(min.slice(6)))
        setMemberMax(parseInt(max))
      }
      setDiscountType(discountType.startsWith("group") ? "group" : "first");
      form.setFieldsValue(transformedDiscount);
    } else {
      form.setFieldsValue({
        name: "",
        description: "",
        percentage: undefined,
        start_date: undefined,
        end_date: undefined,
        eligibility_criteria: "first",
      });
      setDiscountType("first");
    }
  }, [discount, form]);

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      const transformedValues = {
        ...values,
        eligibility_criteria: discountType === "group"
          ? `group ${memberMin}~${memberMax}`
          : "first",
      };
      if (discountId) {
        await updateDiscount(discountId, transformedValues);
      } else {
        await createDiscount(values);
      }
      onSave();
    } catch (error) {
      console.error("There was an error!", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Form.Item
        name="name"
        label="Name"
        rules={[{ required: true, message: "Please input the discount name!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="description" label="Description">
        <Input.TextArea />
      </Form.Item>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          width: "100%",
        }}
      >
        <Form.Item
          name="percentage"
          label="Percentage"
          rules={[
            {
              required: true,
              message: "Please input the discount percentage!",
            },
          ]}
        >
          <InputNumber min={0} max={100} step={0.01} />
        </Form.Item>
        <Form.Item name="start_date" label="Start Date">
          <DatePicker format="MM/DD/YYYY" />
        </Form.Item>
        <Form.Item name="end_date" label="End Date">
          <DatePicker format="MM/DD/YYYY" />
        </Form.Item>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          width: "100%",
        }}
      >
        <Form.Item
          name="eligibility_criteria"
          rules={[
            { required: true, message: "Please select the discount type!" },
          ]}
          label="Eligibility Criteria"
        >
          <Select
            onChange={(value) => setDiscountType(value)}
            value={discountType}
          >
            <Option value="first">First Time User</Option>
            <Option value="group">Group</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Member Count">
          <InputNumber
            disabled={discountType !== "group"}
            required
            value={memberMin}
            onChange={(value: number | null) => value && setMemberMin(value)}
          />
          &nbsp;~&nbsp;
          <InputNumber
            disabled={discountType !== "group"}
            required
            value={memberMax}
            onChange={(value: number | null) => value && setMemberMax(value)}
          />
        </Form.Item>
      </div>
      <Form.Item
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Button type="primary" htmlType="submit" loading={loading}>
          {discountId ? "Update" : "Create"} Discount
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DiscountForm;
