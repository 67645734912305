import { useEffect, useState } from "react";
import { Space } from "antd";
import MemberItem from "../Chat/MemberItem";
import { getEventMembers, deleteUserFromEvent } from "../../api/api";
import { SignupResponse } from "../../types/auth";
import { EventInfo } from "../../types/model";

export interface OtherMembersProps {
  event: EventInfo;
}

const OtherMembers: React.FC<OtherMembersProps> = ({ event }) => {
  const [otherMembers, setOtherMembers] = useState<SignupResponse[]>([]);
  const handleGetOtherMembers = async () => {
    try {
      if (event?.event_id) {
        const response = await getEventMembers(event?.event_id);
        if (response.data) setOtherMembers(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleDeleteUserFromEvent = async (user_id: number) => {
    try {
      if (event?.event_id) {
        const response = await deleteUserFromEvent(event.event_id, user_id);
        if (response.data)
          setOtherMembers((prev) =>
            prev.filter((item) => item?.user_id != user_id)
          );
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    handleGetOtherMembers();
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <h3
        style={{ borderBottom: "1px solid #77777780", paddingBottom: "10px" }}
      >
        Members: {otherMembers.length}
      </h3>
      <Space direction="vertical" style={{ width: "100%" }}>
        {otherMembers.map((otherMember, index) => (
          <MemberItem
            data={otherMember}
            key={index}
            event={event}
            onDelete={() => {
              if (otherMember.user_id)
                handleDeleteUserFromEvent(otherMember.user_id);
            }}
          />
        ))}
      </Space>
    </div>
  );
};

export default OtherMembers;
