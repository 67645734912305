// ProtectedRoute.tsx
import React, { useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useAuth } from "../Provider/AuthContext";
import { useNavigate } from "react-router-dom";
const ProtectedRoute: React.FC = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (auth != null) if (auth.access_token == null) navigate("/login");
  }, [auth]);
  return <Outlet />;
};

export default ProtectedRoute;
