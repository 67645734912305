import styled from "styled-components";
import { SignupResponse } from "../../types/auth";

const FriendItemCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: center;
  text-align: center;
  cursor: pointer;
`;

const API_URL = process.env.REACT_APP_API_URL || "https://api.nook.today";

export interface FriendItemProps {
  data: SignupResponse;
  onClick: () => void;
}

const FriendItem: React.FC<FriendItemProps> = ({ data, onClick }) => {
  return (
    <FriendItemCard onClick={onClick}>
      <img
        src={
          data.avatar_url
            ? API_URL + "/uploads/" + data.avatar_url
            : "user_placeholder.png"
        }
        width={74}
        height={74}
        alt={data.username}
        style={{
          borderRadius: "100px",
          border: "3px solid #EFBF04",
        }}
      />
      <div
        style={{
          fontSize: "14px",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {data.username}
      </div>
    </FriendItemCard>
  );
};

export default FriendItem;
