import { Button, Col, Row, Space } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { addFriend, getMatchMembers } from "../../api/api";
import { User } from "../../types/model";
import MemberCard from "../../Components/Matchmaking/MemberCard";
import ProfileView from "../../Components/UserProfile/ProfileView";
import { EyeOutlined, MessageOutlined } from "@ant-design/icons";
import { useSearch } from "../../Provider/SearchContext";

const Panel = styled.div`
  height: 100%;
  padding: 40px;
  background-color: #202a16;
  border-radius: 16px;
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: start;
`;

const MatchmakingPage = () => {
  const { searchText } = useSearch();
  const [matchMembers, setMatchMembers] = useState<User[]>([]);
  const [viewProfile, setViewProfile] = useState(false);
  const [profileData, setProfileData] = useState({});
  const navigate = useNavigate();
  const [, setLoading] = useState(false);

  const handleGetMatchEvents = async () => {
    try {
      setLoading(true);
      const response = await getMatchMembers();
      if (response.data) {
        let members = response.data;
        if (searchText) {
          members = members.filter((member) =>
            member.username?.toLowerCase().includes(searchText.toLowerCase())
          );
        }
        setMatchMembers(members);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetMatchEvents();
  }, [searchText]);
  return (
    <Panel>
      <h1>Matchmaking</h1>
      <Row
        gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}
        style={{
          width: "100%",
          margin: 0,
        }}
      >
        {matchMembers.length
          ? matchMembers.map((friend, index) => (
              <Col key={index} xs={24} sm={12} md={8} lg={6}>
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "16px",
                    alignItems: "center",
                    borderRadius: "8px",
                    border: "2px solid #5A4803",
                    backgroundColor: "#202A16",
                    paddingRight: "12px",
                    height: "80px",
                    width: "fit-content",
                  }}
                >
                  <div
                    style={{
                      gap: "12px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "240px",
                    }}
                  >
                    <img
                      src={friend.avatar_url || "user_placeholder.png"}
                      width={80}
                      height={80}
                      alt={friend.avatar_url}
                      style={{
                        borderRadius: "8px 4px 4px 8px",
                        border: "2px solid #5A4803",
                        marginLeft: "-2px",
                      }}
                    />
                    <div
                      style={{
                        gap: "2px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div style={{ fontSize: "16px" }}>{friend.username}</div>
                      <div style={{ fontSize: "12px", color: "#BEC2BA" }}>
                        {friend.email}
                      </div>
                    </div>
                  </div>
                  <Space>
                    <Button
                      shape="circle"
                      type="default"
                      onClick={() => {
                        setViewProfile(true);
                        setProfileData(friend);
                      }}
                      icon={<EyeOutlined />}
                    />
                    <Button
                      shape="circle"
                      type="primary"
                      onClick={async () => {
                        const response = await addFriend(friend.user_id!);
                        if (response) {
                          navigate(`/chats/${friend.user_id}`);
                        }
                      }}
                      icon={<MessageOutlined />}
                    />
                  </Space>
                </div>
              </Col>
            ))
          : "Sorry, there are no match members."}
      </Row>
      <ProfileView
        isOpen={viewProfile}
        onClose={() => setViewProfile(false)}
        data={profileData}
      />
    </Panel>
  );
};

export default MatchmakingPage;
