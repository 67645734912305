// src/api/auth.ts
import axios from "axios";
import {
  SignupData,
  SignupResponse,
  SigninData,
  SignupResponseBusiness,
} from "../types/auth";

const API_URL = process.env.REACT_APP_API_URL || "https://api.nook.today";

export const signup = async (data: SignupData): Promise<SignupResponse> => {
  const response = await axios.post(`${API_URL}/api/user/signup`, data);
  return response.data;
};
export const signup_business = async (
  data: SignupData
): Promise<SignupResponseBusiness> => {
  const response = await axios.post(`${API_URL}/api/business/signup`, data);
  return response.data;
};
export const read_me = async (token: string): Promise<SignupResponse> => {
  const response = await axios.get(`${API_URL}/api/user/me`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
export const read_me_business = async (
  token: string
): Promise<SignupResponseBusiness> => {
  const response = await axios.get(`${API_URL}/api/business/me`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
export const signin = async (data: SigninData): Promise<SignupResponse> => {
  const params = new URLSearchParams();
  params.append("username", data.email);
  params.append("password", data.password);
  const response = await axios.post(`${API_URL}/api/user/token`, params);
  console.log(response);
  return response.data;
};
export const signin_business = async (
  data: SigninData
): Promise<SignupResponseBusiness> => {
  const params = new URLSearchParams();
  params.append("username", data.email);
  params.append("password", data.password);
  const response = await axios.post(`${API_URL}/api/business/token`, params);
  console.log(response);
  return response.data;
};
export const socialFacebookLogin = async (
  token: string,
  type: "login" | "profile"
): Promise<SignupResponse> => {
  console.log(token);
  const response = await axios.post(
    `${API_URL}/api/facebook/callback`,
    {
      token: token,
      type,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};
export const socialInstagramLogin = async (
  token: string,
  type: "login" | "profile"
): Promise<SignupResponse> => {
  const response = await axios.post(
    `${API_URL}/api/instagram/callback`,
    {
      token: token,
      type,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};
export const socialLinkedInLogin = async (
  token: string,
  type: "login" | "profile"
): Promise<SignupResponse> => {
  const response = await axios.post(
    `${API_URL}/api/linkedin/callback`,
    {
      token: token,
      type,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};
