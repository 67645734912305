import styled from "styled-components";

import { Button, ConfigProvider, Input } from "antd";
import { useNavigate } from "react-router-dom";
import Alarm from "../Components/Chat/Alarm";
import ProfileItem from "../Components/Header/ProfileItem";
import { useSearch } from "../Provider/SearchContext";
import { useState } from "react";
import { useAuth } from "../Provider/AuthContext";
const { Search } = Input;

const HeaderContainer = styled.div`
  height: 80px;
  border-radius: 8px;
  padding: 20px 32px;
  display: flex;
  flex-direction: row;
  background-color: #8ca375;
  justify-content: space-between;
  align-items: center;
`;

const SubContainer1 = styled.div`
  display: flex;
  flex-direction: row;
  gap: 36px;
  align-items: center;
`;

const SubContainer2 = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
`;

const Header = () => {
  const navigate = useNavigate();
  const { searchText, setSearchText } = useSearch();
  const [search, setSearch] = useState(searchText);
  const { auth } = useAuth();
  return (
    <HeaderContainer>
      <SubContainer1>
        {auth?.access_token && (
          <>
            <ConfigProvider
              theme={{
                components: {
                  Button: {
                    colorBgContainer: "#788F60",
                  },
                  Input: {
                    colorBgContainer: "#788F60",
                    colorTextPlaceholder: "#BEC2BA",
                  },
                },
              }}
            >
              <Search
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                onSearch={(value) => setSearchText(value)}
                size="large"
                style={{ width: "500px" }}
                placeholder="Search People, Event & Venues"
              />
            </ConfigProvider>
            <ConfigProvider
              theme={{
                components: {
                  Button: {
                    colorBgContainer: "#627450",
                  },
                },
              }}
            >
              <Button
                size="large"
                onClick={() => navigate("/rsvps")}
                style={{ borderWidth: "0.5px" }}
              >
                +&nbsp;RSVP
              </Button>
            </ConfigProvider>
          </>
        )}
      </SubContainer1>
      <SubContainer2>
        <Alarm />
        <ProfileItem />
      </SubContainer2>
    </HeaderContainer>
  );
};

export default Header;
