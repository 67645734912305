import { Link } from "react-router-dom";
import { useAuth } from "../../Provider/AuthContext";
import styled from "styled-components";

const API_URL = process.env.REACT_APP_API_URL || "https://api.nook.today";

const ProfileItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 5px 0px;
  color: white;
  font-size: 14px;
`;

const ProfileItem = () => {
  const { auth, auth_business } = useAuth();
  return (
    <Link
      to={auth?.access_token ? "/profile" : "/business/profile"}
      className="profile-item"
    >
      <img
        src={
          !!(auth?.avatar_url || auth_business?.avatar_url)
            ? API_URL +
              "/uploads/" +
              (auth?.avatar_url || auth_business?.avatar_url)
            : "../user_placeholder.png"
        }
        width="40px"
        height="40px"
        alt="Profile"
      />
      <ProfileItemContainer>
        <div>{auth?.username || auth_business?.name}</div>
        <div style={{ color: "#BEC2BA", fontSize: "10px" }}>
          {auth?.access_token ? "User" : "Business"}
        </div>
      </ProfileItemContainer>
    </Link>
  );
};

export default ProfileItem;
