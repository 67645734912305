import React, { useState, useEffect } from "react";
import { Button, Input, FormGroup, Label, PostContainer, Header_medium_theme, Header_large_theme } from "../../Components/Components.styles";
import styled from "styled-components";
import axios from 'axios';

const API_URL = `${
    process.env.REACT_APP_API_URL || "https://api.nook.today"
  }/api/post`;
  
const getToken = () => localStorage.getItem("token");

const PostList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const PostItem = styled.li`
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 10px;
  background-color: #333;
`;

interface Post {
  content: string;
  id: number;
}

const Posts: React.FC = () => {
  const [posts, setPosts] = useState<Post[]>([]);
  const [newPost, setNewPost] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(`${API_URL}/get_posts`, {
        headers: {
            Authorization: `Bearer ${getToken()}`,
            "Content-Type": "application/json",
        },
        });
        setPosts(response.data);
      } catch (error) {
        setError("Error fetching posts. Please try again.");
        console.error("Error fetching posts:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  const handlePostSubmit = async () => {
    if (!newPost) {
      setError("Post content cannot be empty.");
      return;
    }

    setLoading(true);
    setError(null);
    setSuccessMessage(null);

    try {
        const response = await axios.post(`${API_URL}/new_post`, { content: newPost }, {
        headers: {
            Authorization: `Bearer ${getToken()}`,
            "Content-Type": "application/json",
        },
        });

        if (response.status === 200) {
            setPosts([response.data, ...posts]);
            setNewPost("");
            setSuccessMessage("Post created successfully!");
        }
        } catch (error) {
        setError("Error submitting post. Please try again.");
        console.error("Error submitting post:", error);
        } finally {
        setLoading(false);
        }
    };


  return (
    <PostContainer>
      <Header_large_theme>Create a Post</Header_large_theme>

      {error && <div style={{ color: 'red', marginBottom: '10px' }}>{error}</div>}

      {successMessage && <div style={{ color: 'green', marginBottom: '10px' }}>{successMessage}</div>}

      <FormGroup>
        <Label htmlFor="postContent">Post Content:</Label>
        <Input
          id="postContent"
          type="text"
          value={newPost}
          onChange={(e) => setNewPost(e.target.value)}
          placeholder="Write something..."
        />
      </FormGroup>

      <Button onClick={handlePostSubmit} style={{ marginTop: "10px" }} disabled={loading}>
        {loading ? "Submitting..." : "Submit Post"}
      </Button>

      <Header_medium_theme style={{ marginTop: "20px" }}>User Posts</Header_medium_theme>

      {loading && <p>Loading...</p>}

      <PostList>
        {posts.map((post) => (
          <PostItem key={post.id}>{post.content}</PostItem>
        ))}
      </PostList>
    </PostContainer>
  );
};

export default Posts;
