import { createContext, ReactNode, useState, useContext } from "react";
interface data {
  searchText: string | undefined;
  setSearchText: React.Dispatch<React.SetStateAction<string | undefined>>;
}
const SearchContext = createContext<data | undefined>(undefined);

const SearchProvider = ({ children }: { children: ReactNode }) => {
  const [searchText, setSearchText] = useState<string | undefined>(undefined);

  return (
    <SearchContext.Provider value={{ searchText, setSearchText }}>
      {children}
    </SearchContext.Provider>
  );
};
const useSearch = () => {
  const context = useContext(SearchContext);
  if (!context) {
    throw new Error("useSearch must be used within an SearchProvider");
  }
  return context;
};
export { SearchProvider, useSearch };
