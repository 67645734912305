import { Routes, Route } from "react-router-dom";
import HomePage from "../Pages/HomePage";
import AboutPage from "../Pages/AboutPage";
import Registration from "../Pages/Registration";
import Login from "../Pages/Login";
import ErrorPage from "../Pages/ErrorPage";
import EventDetailPage from "../Pages/EventDetail";
import BusinessRoute from "./BusinessRoute";
import UserRoute from "./UserRoute";

const Router = () => {
  return (
    <Routes>
      {BusinessRoute}
      {UserRoute}
      <Route path="/" element={<HomePage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/register" element={<Registration type="user" />} />
      <Route
        path="/business-register"
        element={<Registration type="business" />}
      />
      <Route path="/login" element={<Login type="user" />} />
      <Route path="/business-login" element={<Login type="business" />} />
      <Route path="/error" element={<ErrorPage message="" />} />
      <Route path="/event/:id" element={<EventDetailPage />} />
    </Routes>
  );
};

export default Router;