import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import LogOutIcon from "../../assets/icons/logout.svg";
import { useAuth } from "../../Provider/AuthContext";

const LogOutNavbarItem = () => {
  const { signout } = useAuth();
  const navigate = useNavigate();
  const handleSignOut = () => {
    signout();
    navigate("/");
  };

  return (
    <div onClick={handleSignOut} className="nav-item">
      <div className="item">
        <img src={LogOutIcon} width={24} height={24} alt="icon" />
        <div>Log Out</div>
      </div>
    </div>
  );
};

export default LogOutNavbarItem;
