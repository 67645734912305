import { ReactNode } from "react";
import { ThemeProvider } from "styled-components";
import { SocketContext, socket } from "./SocketContext";
import { UserProvider } from "./UserContext";
import { AuthProvider } from "./AuthContext";
import { DataProvider } from "./DataContext";
import theme from "../theme";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ConfigProvider } from "antd";
import { SearchProvider } from "./SearchContext";

export interface ProviderProps {
  children: ReactNode;
}

const Provider: React.FC<ProviderProps> = ({ children }) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#8CA375",
          colorBorder: "#EFBF04",
        },
        components: {
          Button: {
            colorBgContainer: "#788F60",
            colorBorder: "#ffffff00",
            colorText: "#ffffff",
          },
          Input: {
            colorBgContainer: "#788F60",
            colorBorder: "#ffffff00",
            colorText: "#ffffff",
          },
        },
      }}
    >
      <ThemeProvider theme={theme}>
        <SocketContext.Provider value={socket}>
          <UserProvider socket={socket} username="username">
            <AuthProvider>
              <DataProvider>
                <SearchProvider>
                  <ToastContainer />
                  {children}
                </SearchProvider>
              </DataProvider>
            </AuthProvider>
          </UserProvider>
        </SocketContext.Provider>
      </ThemeProvider>
    </ConfigProvider>
  );
};

export default Provider;
