import React from "react";
import styled from "styled-components";
import ProfileSide from "../../Components/BusinessProfile/ProfileSide";
import ProfileBoard from "../../Components/BusinessProfile/ProfileBoard";

const UserProfileContainer = styled.div`
  display: flex;
  background-color: #202A16;
  border-radius: 16px;
  height: 100%;
`;

const BusinessProfile: React.FC = () => {
  return (
    <UserProfileContainer>
      <ProfileBoard />
      <ProfileSide />
    </UserProfileContainer>
  );
};

export default BusinessProfile;
