import axios from "axios";
import { Discount, DiscountUsage, DiscountStats } from "../types/Discount";

const API_URL = `${
  process.env.REACT_APP_API_URL || "https://api.nook.today"
}/api/discount`;

const getToken = () => localStorage.getItem("token");

export const getAllDiscounts = async (): Promise<{
  data: Discount[];
  error_message: string;
}> => {
  const response = await axios.get(`${API_URL}/all`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const createDiscount = async (discount: Discount): Promise<Discount> => {
  const response = await axios.post(`${API_URL}/create`, discount, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const updateDiscount = async (
  id: number,
  discount: Discount
): Promise<Discount> => {
  const response = await axios.put(`${API_URL}/${id}`, discount, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const deleteDiscount = async (id: number): Promise<void> => {
  await axios.delete(`${API_URL}/${id}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });
};

export const getDiscountByLocation = async (
  location: string
): Promise<{ data: Discount[]; error_message: string }> => {
  const response = await axios.get(`${API_URL}/location/${location}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const createDiscountUsage = async (
  discount_id: number,
  event_id: string
): Promise<DiscountUsage> => {
  const response = await axios.post(
    `${API_URL}/usage`,
    {
      discount_id,
      event_id,
    },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};

export const getDiscountUsages = async (): Promise<{
  data: DiscountUsage[];
  error_message: string;
}> => {
  const response = await axios.get(`${API_URL}/usage`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const getDiscountsStats = async (): Promise<{
  data: DiscountStats[];
  error_message: string;
}> => {
  const response = await axios.get(`${API_URL}/stats`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};
