import { Badge, Button, Card, ConfigProvider, Modal, Space } from "antd";
import { BellOutlined } from "@ant-design/icons";
import { useContext, useEffect, useState } from "react";
import { SocketContext } from "../../Provider/SocketContext";

const Alarm = () => {
  const [alarms, setAlarms] = useState<string[]>([]);
  const socket = useContext(SocketContext);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    socket.on("alarm", (data: string) => {
      setAlarms((prevMessages) => [...prevMessages, data]);
    });
  }, [socket]);

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorBgContainer: "#8CA375",
            },
          },
        }}
      >
        <Button shape="circle" size="large" onClick={() => setIsOpen(true)}>
          <Badge count={alarms.length} size="small">
            <BellOutlined style={{ fontSize: "25px", color: "white" }} />
          </Badge>
        </Button>
      </ConfigProvider>
      <Modal open={isOpen} onCancel={() => setIsOpen(false)} title="New Alarms">
        <Space direction="vertical" style={{ width: "100%" }} size="small">
          {alarms.length
            ? alarms.map((alarm) => <Card hoverable>{alarm}</Card>)
            : "There are no new alarms."}
        </Space>
      </Modal>
    </>
  );
};

export default Alarm;
