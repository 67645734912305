import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_URL || "https://api.nook.today"}/api/follow`;
const getToken = () => localStorage.getItem("token");

export const getFollowsStatus = async (
  user_id: number
): Promise<{
  data?: {
    followers: number;
    following: number;
    like: boolean;
    dislike: boolean;
  };
  error_message?: string;
}> => {
  const response = await axios.get(`${API_URL}/${user_id}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const setLike = async (
  user_id: number
): Promise<{
  data?: boolean;
  error_message?: string;
}> => {
  const response = await axios.get(`${API_URL}/like/${user_id}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const setDislike = async (
  user_id: number
): Promise<{
  data?: boolean;
  error_message?: string;
}> => {
  const response = await axios.get(`${API_URL}/dislike/${user_id}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};
