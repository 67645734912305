import React, { useState, ChangeEvent, useEffect } from "react";
import styled from "styled-components";
import { Button } from "../Components.styles";
import { useAuth } from "../../Provider/AuthContext";
import { uploadAvatar } from "../../api/api";
const API_URL = process.env.REACT_APP_API_URL || "https://api.nook.today";
const AvatarUpload: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const { auth, setAuth } = useAuth();
  useEffect(() => {
    if (auth?.avatar_url) setPreview(API_URL + "/uploads/" + auth?.avatar_url);
  }, [auth?.avatar_url]);
  const fileSelectedHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    const file = event.target.files?.[0] || null;
    setSelectedFile(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };
  const fileUploadHandler = async (): Promise<void> => {
    if (!selectedFile) return;

    const formData = new FormData();
    await formData.append("file", selectedFile);

    setUploading(true);
    const response = await uploadAvatar(formData);
    if (response.url) {
      const user = { ...auth, avatar_url: response.url };
      setAuth(user);
    }
    setUploading(false);
  };

  return (
    <AvatarContainer>
      <Avatar url={preview || "user_placeholder.png"}>
        <AvatarUploadButton>
          <label htmlFor="file-upload" style={{ cursor: "pointer" }}>
            {uploading ? "Uploading..." : "Edit"}
          </label>
          <input
            id="file-upload"
            type="file"
            accept="image/*"
            onChange={fileSelectedHandler}
            style={{ display: "none" }}
          />
        </AvatarUploadButton>
      </Avatar>
      {/* <Button
        style={{ width: "100px", fontSize: "14px" }}
        onClick={fileUploadHandler}
        disabled={uploading || !selectedFile}
      >
        Upload
      </Button> */}
    </AvatarContainer>
  );
};

const AvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Avatar = styled.div<{ url?: string }>`
  width: 84px;
  height: 84px;
  margin: 15px 0px;
  background-size: cover;
  border-radius: 50%;
  background-color: white;
  border: 2px solid #EFBF04;
  position: relative;
  overflow: hidden;
  background-image: url(${(props) => props.url});
`;

const AvatarUploadButton = styled.div`
  position: absolute;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  height: 35%;
  bottom: 0px;
  color: white;
  text-align: center;
  padding-top: 18px;
`;

export default AvatarUpload;
