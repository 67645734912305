// Left side of Profile page
import React from "react";
import { useAuth } from "../../Provider/AuthContext";
import styled from "styled-components";
import {
  Text_large_gray,
  Text_small_gray,
  Line,
  Button,
} from "../Components.styles";
const Avatar = styled.div<{ url?: string }>`
  width: 150px;
  height: 150px;
  margin: 30px auto;
  background-size: contain;
  border-radius: 360px;
  background-color: white;
  background-image: url(${(props) => {
    if (!props.url) return "../user_placeholder.png";
    else return props.url;
  }});
`;
const Side = styled.div`
  flex: 1;
  display: flex;
  gap: 15px;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  background-color: #2c2d32;
  padding: 0px 30px;
`;
const API_URL = process.env.REACT_APP_API_URL || "https://api.nook.today";
const ProfileSide: React.FC = () => {
  const { auth_business } = useAuth();
  return (
    <Side>
      {auth_business?.avatar_url ? (
        <Avatar url={API_URL + "/uploads/" + auth_business?.avatar_url} />
      ) : (
        <Avatar />
      )}
      <Text_large_gray>{auth_business?.name}</Text_large_gray>
      {auth_business?.email ? (
        <Text_small_gray>{auth_business?.email}</Text_small_gray>
      ) : (
        ""
      )}
      {auth_business?.bio ? (
        <Text_small_gray>{auth_business?.bio}</Text_small_gray>
      ) : (
        ""
      )}
      <Line />
      {/* <Button>Account Information</Button> */}
    </Side>
  );
};

export default ProfileSide;
