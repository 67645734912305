import React from 'react';

interface ErrorPageProps {
  message: string | null;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ message }) => {
  return (
    <div>
      <h1>Error</h1>
      <p>{message || 'An unknown error occurred.'}</p>
    </div>
  );
};

export default ErrorPage;