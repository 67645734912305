import { Route } from "react-router-dom";
import ProtectedRouteBusiness from "../Components/ProtectedRouteBusiness";
import Events from "../Pages/Business/Events";
import EventCreatePage from "../Pages/EventCreate";
import DiscountPage from "../Pages/Business/DiscountsPage";
import DashboardPage from "../Pages/Business/DashboardPage";
import BusinessProfile from "../Pages/Business/Profile";
import SettingsPage from "../Pages/SettingsPage";

const BusinessRoute = (
  <Route element={<ProtectedRouteBusiness />}>
    <Route path="/business/dashboard" element={<DashboardPage />} />
    <Route path="/business/events" element={<Events />} />
    <Route path="/business/create-event" element={<EventCreatePage />} />
    <Route path="/business/discounts" element={<DiscountPage />} />
    <Route path="/business/settings" element={<SettingsPage />} />
    <Route path="/business/profile" element={<BusinessProfile />} />
  </Route>
);

export default BusinessRoute;
