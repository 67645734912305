import {
  LoginSocialGoogle,
  LoginSocialFacebook,
  LoginSocialInstagram,
  LoginSocialLinkedin,
  IResolveParams,
} from "reactjs-social-login";
import {
  FacebookLoginButton,
  GoogleLoginButton,
  InstagramLoginButton,
  LinkedInLoginButton,
} from "react-social-login-buttons";
import {
  socialInstagramLogin,
  socialLinkedInLogin,
  socialFacebookLogin,
} from "../api/auth";
import { useAuth } from "../Provider/AuthContext";
import { Space } from "antd";
import { UserInfo } from "../types/model";
// const REDIRECT_URI =
//   'https://plenty-planets-beam-42-118-51-2.loca.lt/account/login';
const REDIRECT_URI = "https://localhost:3000/register";

const SocialButtons = ({
  type = "login",
  onNewData,
}: {
  type?: "login" | "profile";
  onNewData?: (data: UserInfo) => void;
}) => {
  const { setAuth, auth } = useAuth();
  const handleFacebookResolve = async ({ provider, data }: any) => {
    try {
      const response = await socialFacebookLogin(data.accessToken, type);
      if (type === "login") {
        if (response.access_token!)
          localStorage.setItem("token", response.access_token);
        setAuth({ access_token: response.access_token });
      } else {
        console.log(response.facebookData);
        const facebookData = {
          name: response.facebookData?.name,
          email: response.facebookData?.email,
          avatar_url: response.facebookData?.picture?.data?.url,
        };  
        onNewData && onNewData(facebookData);
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };
  const handleInstagramResolve = async ({ provider, data }: any) => {
    try {
      const response = await socialInstagramLogin(data.access_token, type);
      if (type === "login") {
        if (response.access_token!)
          localStorage.setItem("token", response.access_token);
        setAuth({ access_token: response.access_token });
      } else {
        console.log(response.instagramData);
        onNewData && onNewData(response.instagramData);
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };
  const handleLinkedInResolve = async ({ provider, data }: any) => {
    try {
      const response = await socialLinkedInLogin(data.access_token, type);
      if (type === "login") {
        if (response.access_token!)
          localStorage.setItem("token", response.access_token);
        setAuth({ access_token: response.access_token });
      } else {
        console.log(response.linkedinData);
        onNewData && onNewData(response.linkedinData);
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };
  const handleFacebookReject = (err: any) => {
    console.error("Facebook login error:", err);
  };
  const handleInstagramReject = (err: any) => {
    console.error("Instagram login error:", err);
  };
  const handleLinkedInReject = (err: any) => {
    console.error("LinkedIn login error:", err);
  };
  return (
    <Space
      style={
        type === "login"
          ? { width: "100%", gap: "3px" }
          : { width: "100%", marginTop: "15px" }
      }
      direction={type === "login" ? "vertical" : "horizontal"}
    >
      {!(type === "profile" && auth?.linkedin) && (
        <LoginSocialLinkedin
          client_id={process.env.REACT_APP_LINKEDIN_APP_ID || ""}
          client_secret={process.env.REACT_APP_LINKEDIN_APP_SECRET || ""}
          redirect_uri={REDIRECT_URI}
          onResolve={handleLinkedInResolve}
          onReject={handleLinkedInReject}
        >
          <LinkedInLoginButton>
            {type === "login"
              ? "Log in with LinkedIn"
              : "Get data from LinkedIn"}
          </LinkedInLoginButton>
        </LoginSocialLinkedin>
      )}

      {!(type === "profile" && auth?.facebook) && (
        <LoginSocialFacebook
          appId={process.env.REACT_APP_FB_APP_ID || ""}
          fieldsProfile="name,email,picture"
          redirect_uri={REDIRECT_URI}
          onResolve={handleFacebookResolve}
          onReject={handleFacebookReject}
        >
          <FacebookLoginButton>
            {type === "login"
              ? "Log in with Facebook"
              : "Get data from Facebook"}
          </FacebookLoginButton>
        </LoginSocialFacebook>
      )}

      {type === "login" && (
        <LoginSocialGoogle
          client_id={process.env.REACT_APP_GG_APP_ID || ""}
          redirect_uri={REDIRECT_URI}
          scope="openid profile email"
          discoveryDocs="claims_supported"
          access_type="offline"
          onResolve={({ provider, data }: IResolveParams) => {}}
          onReject={(err) => {
            console.log(err);
          }}
        >
          <GoogleLoginButton />
        </LoginSocialGoogle>
      )}

      {!(type === "profile" && auth?.instagram) && (
        <LoginSocialInstagram
          client_id={process.env.REACT_APP_INSTAGRAM_APP_ID || ""}
          client_secret={process.env.REACT_APP_INSTAGRAM_APP_SECRET || ""}
          redirect_uri={REDIRECT_URI}
          onResolve={handleInstagramResolve}
          onReject={handleInstagramReject}
        >
          <InstagramLoginButton>
            {type === "login"
              ? "Log in with Instagram"
              : "Get data from Instagram"}
          </InstagramLoginButton>
        </LoginSocialInstagram>
      )}
    </Space>
  );
};

export default SocialButtons;
