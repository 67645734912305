import { Socket } from "socket.io-client";
import { LoginResponse } from "../types/models";

const fetchUserData = (
  socket: Socket,
  username: string,
  user_id: number
): Promise<LoginResponse> => {
  return new Promise((resolve, reject) => {
    socket.emit("getUser", { username: username, user_id });
    console.log("getUser", username);
    socket.once("getUser:response", (data: LoginResponse) => {
      if (data.success) {
        resolve(data);
      } else {
        reject(new Error(data.message));
      }
    });
  });
};

export default fetchUserData;
