import styled from "styled-components";
import { motion } from "framer-motion";


export const AboutPageContainer = styled(motion.div)`
  margin: 50px auto;
  padding: 20px;
  max-width: 800px;
  background-color: #202a16; /* Match dark background */
  border-radius: 16px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  opacity: 0;
`;

export const Heading = styled(motion.h1)`
  font-weight: bold;
  font-size: 48px;
  color: white; /* Pure white for contrast */
  margin-bottom: 20px;
  text-align: center;
`;

export const SubHeading = styled(motion.h2)`
  font-weight: bold;
  font-size: 32px;
  color: #8ca375; /* Match accent color used for buttons and selected items */
  margin-top: 30px;
  margin-bottom: 20px;
  text-align: center;
`;

export const StyledText = styled(motion.p)`
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.5;
  color: #bec2ba;
  text-align: center;
  opacity: 0;

  &.visible {
    opacity: 1;
    transition: opacity 1s ease-in-out;
  }
`;


export const ContactLink = styled.a`
  color: gray;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
    color: #8ca375; /* Accent color for hover */
  }
`;

export const StyledFeatureList = styled(motion.ul)`
  list-style: none;
  padding: 0;
  margin-top: 20px;
`;

export const StyledFeatureItem = styled(motion.li)`
  margin-bottom: 10px;
  font-size: 16px;
  color: #bec2ba;
  text-align: center;
  opacity: 0;

  &::before {
    content: '•';
    color: #8ca375;
    font-size: 24px;
    margin-right: 10px;
  }
`;

export const HomePageContainer = styled.main`
  padding: 20px;
  width: 100%;
  height: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #202a16; /* Dark greenish background */
  border-radius: 16px; /* Larger border radius for consistency */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
`;
export const Text = styled.p`
  font-size: 18px;
  // color: ${(props) => props.theme.colors.dodger_blue};
  color: white;
  margin: 10px 0;
`;

export const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 20px;
  
  &:hover {
  color: #8ca375; /* Change color on hover */
  transform: scale(1.05); /* Slightly enlarge */
  transition: transform 0.3s ease;
}
`;

export const FeatureItem = styled.li`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;

  &::before {
    content: '•';
    color: ${(props) => props.theme.colors.primary};
    font-size: 24px;
    margin-right: 10px;
  }
`;


export const TeamSection = styled.div`
  margin-top: 30px;
`;

export const TeamMember = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledTeamMemberAvatar = styled.img`
  width: 150px; /* Set the desired width */
  height: 150px; /* Set the desired height */
  object-fit: contain; /* Ensure the entire image fits within the container */
  border-radius: 50%; /* Make the image circular */
  margin-right: 15px; /* Provide some spacing */
  overflow: hidden; /* Hide any overflow */
`;

export const TeamMemberName = styled.div`
  font-weight: bold;
`;

export const StyledTeamMemberName = styled.span`
  font-weight: bold;
  color: ${(props) => props.theme.colors.dodger_blue};
`;


export const ContinueWrapper = styled.div`
position: relative;
height: 16px;
`
export const ContinueLabel = styled.div`
font-size: 14px;
position: absolute;
z-index: 2;
right: 50%;
transform: translateX(50%);
padding:0px 8px;
`