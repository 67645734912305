// Layout.tsx
import React, { ReactNode } from "react";
import Navbar from "./Navbar";
import styled from "styled-components";
import Header from "./Header";
import { useAuth } from "../Provider/AuthContext";

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-family: TT Hoves;
`;

const ContentContainer = styled.div`
  flex: 1;
  background-color: #091002;
  max-height: 100vh;
  color: white;
  padding: 0 14px;
  display: flex;
  flex-direction: column;
  gap: 28px;
`;

const MainContainer = styled.div`
  overflow: auto;
  flex: 1;
  color: white;
  padding-bottom: 14px;
`;

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { auth_business, auth } = useAuth();
  return (
    <LayoutContainer>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Navbar />
        <ContentContainer>
          {!!(auth?.access_token || auth_business?.access_token) && <Header />}
          <MainContainer>{children}</MainContainer>
        </ContentContainer>
      </div>
    </LayoutContainer>
  );
};

export default Layout;
