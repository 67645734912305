import { Button, Card, Popconfirm, Space } from "antd";
import { EventInfo, User } from "../../types/model";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ProfileModal from "../UserProfile/ProfileModal";
import {
  EyeOutlined,
  MessageOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useAuth } from "../../Provider/AuthContext";
import ProfileView from "../UserProfile/ProfileView";
import { SignupResponse } from "../../types/auth";
import { addFriend } from "../../api/api";

export interface MemberItemProps {
  data: SignupResponse;
  user_id?: number;
  event?: EventInfo;
  type?: string;
  selected?: boolean;
  onClick?: () => void;
  onDelete?: () => void;
}

const MemberItem: React.FC<MemberItemProps> = ({
  data,
  user_id,
  event,
  type = "forum",
  selected = false,
  onClick,
  onDelete,
}) => {
  const navigate = useNavigate();
  const { auth_type, auth, auth_business } = useAuth();
  const [viewProfile, setViewProfile] = useState(false);

  return (
    <div
      onClick={onClick}
      style={{
        color: "white",
        padding: "16px 20px",
        borderTop: "1px solid #5A4803",
        backgroundColor:
          type === "chat" ? (selected ? "#485A36" : "#202A16") : "#BEC2BA",
        cursor: "pointer",
        borderBottom: "1px solid #5A4803",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Space style={{ alignItems: "center" }}>
          <img
            width={40}
            height={40}
            alt={data.username}
            src={data.avatar_url || "../user_placeholder.png"}
            style={{ borderRadius: "100px", border: "2px solid #EFBF04" }}
          />
          <div>{data.username}</div>
        </Space>
        <Space>
          {auth_type === "user" && data.username !== auth?.username && (
            <>
              <Button
                shape="circle"
                type={type === "forum" ? "default" : "text"}
                onClick={() => setViewProfile(true)}
                icon={<EyeOutlined />}
              />
              {type !== "chat" && auth_type === "user" && (
                <Button
                  shape="circle"
                  type="primary"
                  onClick={async () => {
                    const response = await addFriend(data.user_id!);
                    if (response) {
                      navigate(`/chats/${data.user_id}`);
                    }
                  }}
                  icon={<MessageOutlined />}
                />
              )}
              {(auth_type === "user"
                ? event?.user_id === auth?.user_id
                : event?.business_id === auth_business?.business_id) && (
                <Popconfirm
                  title="Block the user"
                  description="Are you sure to block this user?"
                  onConfirm={(event) => {
                    event?.stopPropagation();
                    if (onDelete) onDelete();
                  }}
                  onCancel={(event) => {
                    event?.stopPropagation();
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    shape="circle"
                    danger
                    type="primary"
                    icon={<DeleteOutlined />}
                  />
                </Popconfirm>
              )}
            </>
          )}
          {data.username === auth?.username && (
            <div
              style={{
                backgroundColor: "white",
                padding: "2px 4px",
                borderRadius: "20px",
                color: "black",
              }}
            >
              You
            </div>
          )}
        </Space>
      </div>
      <ProfileView
        isOpen={viewProfile}
        onClose={() => setViewProfile(false)}
        data={data}
      />
    </div>
  );
};

export default MemberItem;
