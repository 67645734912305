// Content of Profile page
import React, { useEffect, useState } from "react";
import { useAuth } from "../../Provider/AuthContext";
import { Button, Label, Row } from "../../Components/Components.styles";
import styled from "styled-components";
import { useForm, SubmitHandler } from "react-hook-form";
import { UserInfo, UserInfos } from "../../types/model";
import { updateUser } from "../../api/api";
import AvatarUpload from "./AvatarUpload";
import SocialButtons from "../SocialButtons";
import ProfileUpdateModal from "./ProfileUpdateModal";
import { ConfigProvider, Tabs } from "antd";
import Posts from "./Posts";

const Panel = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 28px;
`;

export const FormGroup = styled.div`
  font-size: 12px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
`;

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const ProfilePanel: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<UserInfos>();
  const { auth, setAuth } = useAuth();
  const [photos, setPhotos] = useState<string[]>([
    "../user_placeholder.png",
    "../user_placeholder.png",
    "../user_placeholder.png",
    "../user_placeholder.png",
  ]);
  const [newData, setNewData] = useState<UserInfo>({});
  useEffect(() => {
    setValue("email", auth?.email);
    setValue("username", auth?.username);
    setValue("phone", auth?.phone);
    setValue("bio", auth?.bio);
    setValue("link1", auth?.link1);
    setValue("link2", auth?.link2);
    setValue("link3", auth?.link3);
    setValue("link4", auth?.link4);
  }, [auth]);
  const onSubmit: SubmitHandler<UserInfos> = async (data) => {
    // Here you can perform your login logic
    const response = await updateUser(data);
    if (!response.error_message) {
      response.access_token = auth?.access_token;
      setAuth(response);
      alert("The user information has been edited!");
    }
  };
  const [profileUpdateModalOpen, setProfileUpdateModalOpen] = useState(false);

  return (
    <Panel>
      <div
        style={{
          padding: "30px 40px",
          backgroundColor: "#202A16",
          borderRadius: "16px",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <h2>Social Buttons</h2>
        <SocialButtons
          type="profile"
          onNewData={(data) => {
            setNewData(data);
            setProfileUpdateModalOpen(true);
          }}
        />
      </div>
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              cardPadding: "10px 20px",
              fontSize: 16,
              colorBorderSecondary: "#5A4803",
              cardBg: "transparent",
              colorBgContainer: "#485A36",
              itemColor: "#BEC2BA",
              itemSelectedColor: "white",
            },
          },
        }}
      >
        <Tabs
          tabPosition="top"
          type="card"
          items={[
            {
              label: (
                <div style={{ width: "300px", textAlign: "center" }}>
                  About
                </div>
              ),
              key: "info",
              children: (
                <div
                  style={{
                    backgroundColor: "#202A16",
                    padding: "40px",
                    gap: "24px",
                    borderRadius: "16px",
                    display: "flex",
                    flexDirection: "column",
                    color: "white",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "30px",
                      alignItems: "center",
                    }}
                  >
                    <AvatarUpload />
                    <div>{auth?.username}</div>
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      <FormGroup>
                        <Label htmlFor="email">Email:</Label>
                        <Input
                          id="email"
                          type="text"
                          {...register("email", {})}
                        />
                        {errors.email && <span>{errors.email.message}</span>}
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="username">Name:</Label>
                        <Input
                          id="username"
                          type="username"
                          {...register("username", {
                            required: "username is required",
                          })}
                        />
                        {errors.username && (
                          <span>{errors.username.message}</span>
                        )}
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup>
                        <Label htmlFor="phone">Phone:</Label>
                        <Input
                          id="phone"
                          type="phone"
                          {...register("phone", {
                            minLength: {
                              value: 10,
                              message: "phone must be at least 10 characters",
                            },
                          })}
                        />
                        {errors.phone && <span>{errors.phone.message}</span>}
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="bio">bio:</Label>
                        <Input id="bio" type="bio" {...register("bio")} />
                        {errors.bio && <span>{errors.bio.message}</span>}
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup>
                        <Label htmlFor="link1">Link1:</Label>
                        <Input id="link1" type="link1" {...register("link1")} />
                        {errors.link1 && <span>{errors.link1.message}</span>}
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="link2">Link2:</Label>
                        <Input id="link2" type="link2" {...register("link2")} />
                        {errors.link2 && <span>{errors.link2.message}</span>}
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup>
                        <Label htmlFor="link3">Link3:</Label>
                        <Input id="link3" type="link3" {...register("link3")} />
                        {errors.link3 && <span>{errors.link3.message}</span>}
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="link4">Link4:</Label>
                        <Input id="link4" type="link4" {...register("link4")} />
                        {errors.link4 && <span>{errors.link4.message}</span>}
                      </FormGroup>
                    </Row>
                    <Button
                      type="submit"
                      style={{
                        marginTop: "15px",
                        width: "300px",
                        float: "right",
                      }}
                    >
                      Save
                    </Button>
                  </form>
                </div>
              ),
            },
            {
              label: (
                <div style={{ width: "300px", textAlign: "center" }}>
                  Photos
                </div>
              ),
              key: "photos",
              children: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    color: "white",
                  }}
                >
                  <h2>Photos</h2>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      overflow: "auto",
                      gap: "10px",
                    }}
                  >
                    {photos.map((photo, index) => (
                      <img
                        src={photo}
                        width={322}
                        height={375}
                        alt={photo}
                        style={{ borderRadius: "16px" }}
                      />
                    ))}
                  </div>
                </div>
              ),
            },
            {
              label: (
                <div style={{ width: "300px", textAlign: "center" }}>
                  Posts
                </div>
              ),
              key: "posts",
              children: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    color: "white",
                  }}
                >
                  <h2>Posts</h2>
                  <Posts />
                </div>
              ),
            },
          ]}
        />
      </ConfigProvider>
      <ProfileUpdateModal
        open={profileUpdateModalOpen}
        onOk={async (data: UserInfo) => {
          const response = await updateUser(data);
          if (!response.error_message) {
            response.access_token = auth?.access_token;
            setAuth(response);
            alert("The user information has been edited!");
          }
          setProfileUpdateModalOpen(false);
        }}
        onCancel={() => setProfileUpdateModalOpen(false)}
        newData={newData}
      />
    </Panel>
  );
};

export default ProfilePanel;
