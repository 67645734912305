import { Button, Card, Popconfirm, Space } from "antd";
import { Message } from "../../types/Forum";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useAuth } from "../../Provider/AuthContext";
import moment from "moment";

export interface MessageItemProps {
  data: Message;
  onUpdate: () => void;
  onDelete: () => void;
  selected: boolean;
}

const MessageItem: React.FC<MessageItemProps> = ({
  data,
  onUpdate,
  onDelete,
  selected,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const { auth_type, auth } = useAuth();

  return (
    <Card
      hoverable
      size="small"
      bordered={selected}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        minWidth: "200px",
        backgroundColor: "#788F60",
        borderColor: "#EFBF04",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          minHeight: "30px",
        }}
      >
        <div
          style={{
            textDecoration: "underline",
          }}
        >
          {data?.username}
        </div>
        <Space>
          {isHovered &&
            auth_type === "user" &&
            auth?.username === data?.username && (
              <>
                <Button
                  size="small"
                  key="edit"
                  onClick={(event) => {
                    event.stopPropagation();
                    onUpdate();
                  }}
                  type="primary"
                  shape="circle"
                  icon={<EditOutlined />}
                />
                <Popconfirm
                  title="Delete the forum"
                  description="Are you sure to delete this forum?"
                  onConfirm={(event) => {
                    event?.stopPropagation();
                    onDelete();
                  }}
                  onCancel={(event) => {
                    event?.stopPropagation();
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    danger
                    size="small"
                    type="primary"
                    shape="circle"
                    key="delete"
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    icon={<DeleteOutlined />}
                  />
                </Popconfirm>
              </>
            )}
        </Space>
      </div>
      <pre
        style={{ wordBreak: "break-all", font: "inherit", padding: "0px 3px" }}
      >
        {data.content}
      </pre>
      <div style={{ float: "right", fontSize: "11px" }}>
        {moment(data?.created_at).format("MM/DD/YYYY HH:mm")}
      </div>
    </Card>
  );
};

export default MessageItem;
