const DiscountPercentageItem = ({
  percentage,
  type,
}: {
  percentage: number;
  type: string;
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: type === "first" ? "#eb2f96" : "red",
          fontSize: type === "first" ? "72px" : "32px",
          borderRadius: "999px",
          border: type === "first" ? "10px dashed #eb2f96" : "4px dashed red",
          width: type === "first" ? "200px" : "100px",
          height: type === "first" ? "200px" : "100px",
        }}
      >
        {percentage}%
      </div>
    </div>
  );
};

export default DiscountPercentageItem;
