import { Card, Rate, Row, Space } from "antd";

export interface PlaceItemProps {
  name: string;
  rating: number | "N/A";
  proximity: string | 0 | null;
  friendRating?: number;
  selected: boolean;
  onSelect: () => void;
}

const PlaceItem: React.FC<PlaceItemProps> = ({
  selected,
  name,
  rating,
  proximity,
  friendRating,
  onSelect,
}) => {
  return (
    <Card
      size="small"
      title={name}
      hoverable
      onClick={onSelect}
      style={{
        border: selected ? "1px solid black" : "1px solid #ddd",
      }}
    >
      <Space direction="vertical">
        <Row>
          Rating:&nbsp;&nbsp;&nbsp;{rating === "N/A" ? "N/A" : <Rate value={rating} disabled />}
        </Row>
        {friendRating && (
          <Row>
            Friend Rating:&nbsp;&nbsp;&nbsp; <Rate value={friendRating} disabled />
          </Row>
        )}
        <Row>Proximity: {proximity}</Row>
      </Space>
    </Card>
  );
};

export default PlaceItem;
