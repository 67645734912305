import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';
import { Socket } from 'socket.io-client';
import fetchUserData from '../api/fetchUserData';
import { LoginResponse, UserProfileProps } from '../types/models';
import useSessionStorageState from '../utils/storage';

export type UserContextType = {
  error: string | null;
  userData: UserProfileProps | null;
  setUser: (userData: UserProfileProps | null) => void;
  user_id: number;
  setUserId: (user_id: number) => void;
  username: string;
  setUsername: (username: string) => void;
  email: string;
  setEmail: (email: string) => void;
  is_signed_in: boolean;
  setIsSignedIn: (value: boolean) => void;
  friends: UserProfileProps[];
  setFriends: (friends: UserProfileProps[]) => void;
};

const UserContext = createContext<UserContextType | undefined>(undefined);

export const useUserContext = (): UserContextType => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

interface UserProviderProps {
  socket: Socket;
  username: string;
  children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ socket, children }) => {
  const [userData, setUserData] = useSessionStorageState<UserProfileProps | null>('user_data', null);
  const [error, setError] = useState<string | null>(null);
  const [user_id, setUserId] = useSessionStorageState<number>('user_id', 0);
  const [username, setUsername] = useSessionStorageState<string>('username', '');
  const [email, setEmail] = useSessionStorageState<string>('email', '');
  const [is_signed_in, setIsSignedIn] = useSessionStorageState<boolean>('is_signed_in', false);
  const [friends, setFriends] = useSessionStorageState<UserProfileProps[]>('friends', []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data: LoginResponse = await fetchUserData(socket, username, user_id);
        if (data.success && data.user) {
          setUserData(data.user);
          setUserId(data.user.user_id);
          setUsername(data.user.username);
          setEmail(data.user.email);
          setIsSignedIn(data.user.signed_in);
          setFriends(data.user.friends);
          setError(null);
        } else {
          setError(data.message || 'Unknown error');
        }
      } catch (error) {
        console.error('Failed to fetch user data:', error);
        setError('Failed to fetch user data');
      }
    };

    fetchData();
  }, [socket, username]);

  const setUser = (userData: UserProfileProps | null) => {
    setUserData(userData);
    if (userData) {
      setUserId(userData.user_id);
      setUsername(userData.username);
      setEmail(userData.email);
      setIsSignedIn(userData.signed_in);
      setFriends(userData.friends);
    } else {
      setUserId(0);
      setUsername('');
      setEmail('');
      setIsSignedIn(false);
      setFriends([]);
    }
  };

  const contextValue: UserContextType = {
    userData,
    error,
    setUser,
    user_id,
    setUserId,
    username,
    setUsername,
    email,
    setEmail,
    is_signed_in,
    setIsSignedIn,
    friends,
    setFriends,
  };

  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
