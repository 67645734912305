import { Route } from "react-router-dom";
import ProtectedRoute from "../Components/ProtectedRoute";
import UserProfile from "../Pages/User/UserProfile";
import EventSearch from "../Pages/User/EventSearch";
import MessagePage from "../Pages/User/MessagePage";
import EventCreatePage from "../Pages/EventCreate";
import MyConnectionsPage from "../Pages/User/MyConnectionsPage";
import MatchmakingPage from "../Pages/User/MatchmakingPage";
import RsvpsPage from "../Pages/User/RsvpsPage";
import SettingsPage from "../Pages/SettingsPage";
import FeedPage from "../Pages/User/FeedPage";

const UserRoute = (
  <Route element={<ProtectedRoute />}>
    <Route path="/feed" element={<FeedPage />} />
    <Route path="/events" element={<EventSearch />} />
    <Route path="/create-event" element={<EventCreatePage />} />
    <Route path="/connections" element={<MyConnectionsPage />} />
    <Route path="/chats/:id?" element={<MessagePage />} />
    <Route path="/matchmaking" element={<MatchmakingPage />} />
    <Route path="/rsvps" element={<RsvpsPage />} />
    <Route path="/settings" element={<SettingsPage />} />
    <Route path="/profile" element={<UserProfile />} />
  </Route>
);

export default UserRoute;
