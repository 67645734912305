import styled from "styled-components";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { addFriend, getMatchMembers, getUpcomingEvents } from "../../api/api";
import { EventInfo, User } from "../../types/model";
import { Button, Space } from "antd";
import {
  MessageOutlined,
  EyeOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import EventCard from "./EventCard";
import moment from "moment";
import ProfileView from "../UserProfile/ProfileView";
import { useSearch } from "../../Provider/SearchContext";

const RightSidebarPanel = styled.div`
  display: flex;
  width: 400px;
  overflow-y: auto;
  flex-direction: column;
  gap: 30px;
  border-radius: 16px;
  padding: 26px 26px 0px 26px;
  background-color: #202a16;
`;

const RightSidebar = () => {
  const { searchText } = useSearch();
  const [matchMembers, setMatchMembers] = useState<User[]>([]);
  const [upcomingEvents, setUpcomingEvents] = useState<EventInfo[]>([]);
  const [viewProfile, setViewProfile] = useState(false);
  const [profileData, setProfileData] = useState({});
  const navigate = useNavigate();
  const [, setLoading] = useState(false);
  const handleGetMatchEvents = async () => {
    try {
      setLoading(true);
      const response = await getMatchMembers();
      if (response.data) {
        let members = response.data;
        if (searchText) {
          members = members.filter(
            (member) =>
              member.username
                ?.toLowerCase()
                .includes(searchText.toLowerCase()) ||
              member.email?.toLowerCase().includes(searchText.toLowerCase())
          );
        }
        setMatchMembers(members);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleGetUpcomingEvents = async () => {
    try {
      setLoading(true);
      const response = await getUpcomingEvents();
      if (response.data) {
        let events = response.data;
        if (searchText) {
          events = events.filter((event) =>
            event.name?.toLowerCase().includes(searchText.toLowerCase())
          );
        }
        setUpcomingEvents(events);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetUpcomingEvents();
    handleGetMatchEvents();
  }, [searchText]);

  return (
    <RightSidebarPanel>
      <div style={{ display: "flex", flexDirection: "column", gap: "28px" }}>
        <div>Friend Suggestions</div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            borderBottom: "1px solid #5A4803",
            fontSize: "14px",
          }}
        >
          {matchMembers.length
            ? matchMembers.map((friend, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  paddingRight: "12px",
                  height: "72px",
                  justifyContent: "space-between",
                  borderTop: "1px solid #5A4803",
                }}
              >
                <div
                  style={{
                    gap: "12px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    height: "40px",
                  }}
                >
                  <img
                    src={friend.avatar_url || "user_placeholder.png"}
                    width={40}
                    height={40}
                    alt={friend.avatar_url}
                    style={{
                      borderRadius: "100px",
                      border: "2px solid #EFBF04",
                    }}
                  />
                  <div
                    style={{
                      gap: "2px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ fontSize: "14px" }}>{friend.username}</div>
                    <div style={{ fontSize: "12px", color: "#BEC2BA" }}>
                      {friend.email}
                    </div>
                  </div>
                </div>
                <Space>
                  <Button
                    shape="circle"
                    type="default"
                    onClick={() => {
                      setViewProfile(true);
                      setProfileData(friend);
                    }}
                    icon={<EyeOutlined />}
                  />
                  <Button
                    shape="circle"
                    type="primary"
                    onClick={async () => {
                      const response = await addFriend(friend.user_id!);
                      if (response) {
                        navigate(`/chats/${friend.user_id}`);
                      }
                    }}
                    icon={<MessageOutlined />}
                  />
                </Space>
              </div>
            ))
            : <div style={{ fontSize: "12px" }} >Sorry, there are no match members.</div>}
        </div>
      </div>
      {/* <div style={{ display: "flex", flexDirection: "column", gap: "28px" }}>
        <div>Profile Activity</div>
        <div
          style={{ display: "flex", flexDirection: "column", gap: "10px" }}
        ></div>
      </div> */}
      <div style={{ display: "flex", flexDirection: "column", gap: "28px" }}>
        <div>Upcoming Events</div>
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          {upcomingEvents.length ? upcomingEvents.map((event, index) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "12px",
                cursor: "pointer",
              }}
              onClick={() => navigate(`/event/${event.event_id}`)}
            >
              <div
                style={{
                  backgroundColor: "#5A4803",
                  borderRadius: "99px",
                  padding: "8px",
                  width: "40px",
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CalendarOutlined />
              </div>
              <div
                style={{ display: "flex", flexDirection: "column", gap: "2px" }}
              >
                <div style={{ fontSize: "14px", color: "white" }}>
                  {event.name}
                </div>
                <div style={{ fontSize: "12px", color: "#BEC2BA" }}>
                  {moment(event.begin_at).format("ddd DD MMM - h:mm A ")}
                </div>
              </div>
            </div>
          )) : <div style={{ fontSize: "12px", marginBottom: "20px" }}>There are no upcoming events.</div>}
        </div>
      </div>
      <ProfileView
        isOpen={viewProfile}
        onClose={() => setViewProfile(false)}
        data={profileData}
      />
    </RightSidebarPanel>
  );
};

export default RightSidebar;
