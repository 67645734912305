import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Button, message } from "antd";

export interface CheckoutFormProps {
  onSubmit: () => void;
  onCancel: () => void;
}

const CheckoutForm: React.FC<CheckoutFormProps> = ({ onSubmit, onCancel }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: any) => {
    try {
      event.preventDefault();
      if (!stripe || !elements) {
        return;
      }
      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {},
        redirect: "if_required",
      });
      if (result.error) {
        console.log(result.error.message);
      } else {
        // Handle the payment status directly
        if (result.paymentIntent.status === "succeeded") {
          onSubmit(); // Optionally, handle any post-payment success actions
        } else {
          message.error(`Payment status:${result.paymentIntent.status}`);
          // Handle other payment statuses if needed
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          gap: "10px",
          marginTop: "10px",
        }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
        <Button onClick={onCancel} htmlType="button">
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default CheckoutForm;
