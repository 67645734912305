import React, { useState } from "react";
import styled from "styled-components";
import { useData } from "../Provider/DataContext";
import {
  Button,
  ConfigProvider,
  Divider,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Tabs,
  notification,
} from "antd";
import LocationCard from "../Components/Events/LocationCard";
import { createEvent as create_Event } from "../api/api";
import { useAuth } from "../Provider/AuthContext";
import EventModal from "../Components/Events/EventModal";

const { Option } = Select;
const { TextArea } = Input;

const EventCreatePanel = styled.div`
  height: 100%;
  background-color: #202a16;
  border-radius: 16px;
  padding: 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: start;
`;

const EventCreatePage: React.FC = () => {
  const [api, contextHolder] = notification.useNotification();
  const [distance, setDistance] = useState<number>(5);
  const [distanceType, setDistanceType] = useState<"K" | "M">("K");
  const [placeType, setPlaceType] = useState<
    | "bar"
    | "restaurant"
    | "night_club"
    | "concert_hall"
    | "movie_theater"
    | "theater"
  >("bar");
  const [modalVisible, setModalVisible] = useState(false);
  const { event, setEvent } = useData();
  const { auth_type } = useAuth();

  const createEvent = async () => {
    if (event?.location && event?.description && event?.name) {
      const response = await create_Event({
        auth_type,
        location: event.location,
        description: event.description,
        name: event.name,
        begin_at: event.begin_at,
        end_at: event.end_at,
      });
      if (!response.error_message) return true;
    }
    return false;
  };

  const handleCreateEvent = async () => {
    const is_success = await createEvent();
    setModalVisible(false);
    if (is_success) {
      api.open({
        key: "success",
        type: "success",
        message: "Success",
        description: "Success to create event.",
      });
    } else {
      api.open({
        key: "error",
        type: "error",
        message: "Error",
        description: "Failed to create event.",
      });
    }
  };

  const filterBar = (
    <ConfigProvider
      componentSize="large"
      theme={{
        components: {
          Select: {
            optionSelectedBg: "#8ca375",
            colorBgContainer: "#202a16",
            colorText: "#BEC2BA",
            colorBorder: "#5A4803",
          },
          InputNumber: {
            colorBgContainer: "#202a16",
            colorTextPlaceholder: "#BEC2BA",
            colorText: "#BEC2BA",
            colorBorder: "#5A4803",
          },
          DatePicker: {
            colorBgContainer: "#202a16",
            colorText: "#BEC2BA",
            colorBorder: "#5A4803",
          },
          Button: {
            colorBgContainerDisabled: "#8CA375"
          }
        },
      }}
    >
      <Space
        size="middle"
        style={{ justifyContent: "center", width: "100%", color: "white" }}
      >
        <Space>
          <div>Locations within:</div>
          <InputNumber
            min={1}
            value={distance}
            onChange={(value) => setDistance(value!)}
            addonAfter={
              <Select
                value={distanceType}
                onChange={(value) => setDistanceType(value)}
              >
                <Option value="K">km</Option>
                <Option value="M">mile</Option>
              </Select>
            }
            defaultValue={100}
          />
        </Space>
        <Space>
          <div>Place type:</div>
          <Select value={placeType} onChange={(value) => setPlaceType(value)}>
            <Option value="bar">Bars</Option>
            <Option value="restaurant">Restaurants</Option>
            <Option value="night_club">Clubs</Option>
            <Option value="concert_hall">Concerts</Option>
            <Option value="movie_theater">Shows</Option>
            <Option value="theater">Theaters</Option>
          </Select>
        </Space>
        <Button
          type="primary"
          disabled={!event?.location}
          onClick={() => setModalVisible(true)}
        >
          +&nbsp;Create with this place
        </Button>
      </Space>
    </ConfigProvider>
  );

  return (
    <EventCreatePanel>
      {contextHolder}
      <h1>Host an Event</h1>
      <Tabs
        tabPosition="left"
        style={{ padding: "20px" }}
        items={[
          {
            label: (
              <div style={{ color: "white", width: "120px" }}>Suggested</div>
            ),
            key: "suggest",
            children: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "30px",
                }}
              >
                {filterBar}
                <LocationCard
                  type="suggest"
                  distance={distance}
                  distanceType={distanceType}
                  placeType={placeType}
                />
              </div>
            ),
          },
          {
            label: (
              <div style={{ color: "white", width: "120px" }}>Find myself</div>
            ),
            key: "myself",
            children: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "30px",
                }}
              >
                {filterBar}
                <LocationCard
                  type="myself"
                  distance={distance}
                  distanceType={distanceType}
                  placeType={placeType}
                />
              </div>
            ),
          },
        ]}
      />
      <EventModal
        open={modalVisible}
        onOk={() => handleCreateEvent()}
        onCancel={() => setModalVisible(false)}
        name={event?.name || ""}
        description={event?.description || ""}
        location={event?.location || ""}
        begin_at={event?.begin_at}
        end_at={event?.end_at}
        onChangeName={(name: string) => setEvent({ ...event, name })}
        onChangeDescription={(description: string) =>
          setEvent({ ...event, description })
        }
        onBeginAt={(date: Date) => setEvent({ ...event, begin_at: date })}
        onEndAt={(date: Date) => setEvent({ ...event, end_at: date })}
      />
    </EventCreatePanel>
  );
};

export default EventCreatePage;
