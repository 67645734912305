const toRadians = (value: number): number => (value * Math.PI) / 180;

export const calculateRadius = (map: google.maps.Map): number => {
  const bounds = map.getBounds();
  if (!bounds) return 0;

  const center = bounds.getCenter();
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();

  const calculateDistance = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
              Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
              Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    return distance * 1000; // Convert to meters
  };

  const latCenter = center.lat();
  const lngCenter = center.lng();
  const latNorth = ne.lat();
  const lngEast = ne.lng();
  const latSouth = sw.lat();
  const lngWest = sw.lng();

  const radiusNorth = calculateDistance(latCenter, lngCenter, latNorth, lngCenter);
  const radiusSouth = calculateDistance(latCenter, lngCenter, latSouth, lngCenter);
  const radiusEast = calculateDistance(latCenter, lngCenter, latCenter, lngEast);
  const radiusWest = calculateDistance(latCenter, lngCenter, latCenter, lngWest);

  const radius = Math.max(radiusNorth, radiusSouth, radiusEast, radiusWest);
  return radius;
};

export const calculateProximity = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
  unit: 'K' | 'M'
): number => {
  const R = unit === 'K' ? 6371 : 3958.8; // Earth's radius in kilometers or miles
  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;
  return distance;
};
