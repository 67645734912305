import { useAuth } from "../../Provider/AuthContext";
import SettingIcon from "../../assets/icons/setting.svg";
import LogOutNavbarItem from "./LogOutNavbarItem";
import LogInIcon from "../../assets/icons/login.svg";
import NavbarItem from "./NavbarItem";

const BottomNavbarItem = () => {
  const { auth, auth_business } = useAuth();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "14px",
      }}
    >
      {auth?.access_token || auth_business?.access_token ? (
        <>
          <NavbarItem
            badge={0}
            content="Settings"
            pathname="/setting"
            key="setting"
            icon={SettingIcon}
          />
          <LogOutNavbarItem />
        </>
      ) : (
        <NavbarItem
          content="Log In"
          pathname="/login"
          key="login"
          icon={LogInIcon}
          badge={0}
        />
      )}
    </div>
  );
};

export default BottomNavbarItem;
