const theme = {
  colors: {
    dodger_blue: "#3c80fa",
    meteor_shower: "#573cfa",
    magento: "#b63cfa",
    once_in_a_blue_moon: "#0544b5",
    carbon: "#333333",
    stone_cold: "#555555",
  },
};

export default theme;
