import { Row, Col, Space, Button, Modal, Image, ConfigProvider } from "antd";
import { useEffect, useState } from "react";
import { SignupResponse } from "../../types/auth";
import { LikeOutlined, DislikeOutlined } from "@ant-design/icons";
import {
  getFollowsStatus,
  setLike as setLikeStatus,
  setDislike as setDislikeStatus,
} from "../../api/followService";
import { useAuth } from "../../Provider/AuthContext";

export interface ProfileViewProps {
  data: SignupResponse;
  preview?: boolean;
  isOpen: boolean;
  onClose: () => void;
}
const API_URL = process.env.REACT_APP_API_URL || "https://api.nook.today";
const ProfileView: React.FC<ProfileViewProps> = ({
  data,
  preview = false,
  isOpen,
  onClose,
}) => {
  const [followers, setFollowers] = useState(0);
  const [following, setFollowing] = useState(0);
  const [like, setLike] = useState(false);
  const [dislike, setDislike] = useState(false);
  const { auth_type } = useAuth();

  const handleGetFollowsStatus = async () => {
    if (data.user_id) {
      const response = await getFollowsStatus(data.user_id);
      if (response.data) {
        setFollowers(response.data.followers);
        setFollowing(response.data.following);
        setLike(response.data.like);
        setDislike(response.data.dislike);
      }
    }
  };

  const handleSetLike = async () => {
    if (data.user_id) {
      const response = await setLikeStatus(data.user_id);
      if (response.data) {
        handleGetFollowsStatus();
      }
    }
  };

  const handleSetDisLike = async () => {
    if (data.user_id) {
      const response = await setDislikeStatus(data.user_id);
      if (response.data) {
        handleGetFollowsStatus();
      }
    }
  };

  useEffect(() => {
    handleGetFollowsStatus();
  }, [data]);

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      title={data.username}
      footer={[
        <Space style={{ justifyContent: "space-between", width: "100%" }}>
          {!preview && auth_type === "user" && (
            <ConfigProvider
              theme={{
                components: {
                  Button: {
                    colorBgContainer: "white",
                  },
                },
              }}
            >
              <Space>
                <Button
                  key="like"
                  icon={<LikeOutlined />}
                  onClick={handleSetLike}
                  style={
                    like ? {} : { borderColor: "#4096FF", color: "#4096FF" }
                  }
                  type={like ? "primary" : "default"}
                />
                <Button
                  key="dislike"
                  danger
                  onClick={handleSetDisLike}
                  icon={<DislikeOutlined />}
                  style={
                    dislike ? {} : { borderColor: "#ff7875", color: "#ff7875" }
                  }
                  type={dislike ? "primary" : "default"}
                />
              </Space>
            </ConfigProvider>
          )}
          <Space>
            <div>Followers: {followers}</div>
            <div>Following: {following}</div>
          </Space>
          <Button key="close" onClick={onClose}>
            Close
          </Button>
        </Space>,
      ]}
    >
      <Space
        style={{ justifyContent: "center", width: "100%" }}
        direction="vertical"
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Image
            style={{
              borderRadius: "999px",
              width: "200px",
              height: "200px",
            }}
            src={
              data.avatar_url
                ? API_URL + "/uploads/" + data.avatar_url
                : "../user_placeholder.png"
            }
          />
        </div>
        <Row
          gutter={[8, 8]}
          style={{ alignItems: "center", margin: "0px 20px" }}
        >
          {data.bio && (
            <>
              <Col span={6}>Bio:</Col>
              <Col span={18}>{data.bio}</Col>
            </>
          )}
          {data.email && (
            <>
              <Col span={3}>Email:</Col>
              <Col span={9}>{data.email}</Col>
            </>
          )}
          {data.phone && (
            <>
              <Col span={3}>Phone:</Col>
              <Col span={9}>{data.phone}</Col>
            </>
          )}
          {data.link1 && (
            <>
              <Col span={3}>Link1:</Col>
              <Col span={9}>{data.link1}</Col>
            </>
          )}
          {data.link2 && (
            <>
              <Col span={3}>Link2:</Col>
              <Col span={9}>{data.link2}</Col>
            </>
          )}
          {data.link3 && (
            <>
              <Col span={3}>Link3:</Col>
              <Col span={9}>{data.link3}</Col>
            </>
          )}
          {data.link4 && (
            <>
              <Col span={3}>Link4:</Col>
              <Col span={9}>{data.link4}</Col>
            </>
          )}
        </Row>
      </Space>
    </Modal>
  );
};

export default ProfileView;
