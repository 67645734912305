import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  FormContainer,
  FormGroup,
  Input,
  Label,
  Title,
  Loginline,
} from "../Components/Components.styles";
import { Link, useNavigate } from "react-router-dom"; // Change import to use Routes and Route
import Alert from "../types/Alert";
import { useForm, SubmitHandler } from "react-hook-form";
import { ContinueLabel, ContinueWrapper } from "./Pages.styles";
import SocailButtons from "../Components/SocialButtons";
import { useAuth } from "../Provider/AuthContext";
import { signup, signup_business } from "../api/auth";
interface FormValues {
  username: string;
  email: string;
  password: string;
  confirmPassword: string;
}
const Login: React.FC<{ type: "user" | "business" }> = ({ type = "user" }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormValues>();
  const { setAuth, setAuthBusiness, setAuthType } = useAuth();
  const password = watch("password");
  const navigate = useNavigate();
  const [statusText, setStatusText] = useState<string>("");
  // Watch password field to validate confirmPassword
  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    // Here you can perform your login logic
    setStatusText("Logging in...");
    if (type == "user") {
      const response = await signup({
        email: data.email,
        password: data.password,
        username: data.username,
      });
      console.log(response);
      if (response.error_message != null) setStatusText(response.error_message);
      else {
        setAuth(response);
        setAuthType("user");
        navigate("/profile");
        setStatusText("Registration successful");
      }
    } else {
      const response = await signup_business({
        email: data.email,
        password: data.password,
        username: data.username,
      });
      console.log(response);
      if (response.error_message != null) setStatusText(response.error_message);
      else {
        setAuthBusiness(response);
        setAuthType("business");
        navigate("/profile");
        setStatusText("Registration successful");
      }
    }
  };

  return (
    <>
      {type == "user" ? (
        <FormContainer>
          <Title>Registration</Title>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Label htmlFor="username">Username:</Label>
              <Input
                id="username"
                type="username"
                {...register("username", {
                  required: "Username is required",
                })}
              />
              {errors.username && <span>{errors.username.message}</span>}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="email">Email:</Label>
              <Input
                id="email"
                type="email"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: "Invalid email address",
                  },
                })}
              />
              {errors.email && <span>{errors.email.message}</span>}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="password">Password:</Label>
              <Input
                id="password"
                type="password"
                {...register("password", {
                  required: "Password is required",
                  minLength: {
                    value: 6,
                    message: "Password must be at least 6 characters",
                  },
                })}
              />
              {errors.password && <span>{errors.password.message}</span>}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="confirmPassword">Confirm Password:</Label>
              <Input
                id="confirmPassword"
                type="password"
                {...register("confirmPassword", {
                  required: "Confirm Password is required",
                  validate: (value) =>
                    value === password || "Passwords do not match",
                })}
              />
              {errors.password && <span>{errors.password.message}</span>}
            </FormGroup>
            <Button type="submit" style={{ marginTop: "15px" }}>
              Register
            </Button>
          </form>
          <p style={{ textAlign: "center", marginTop: "10px" }}>
            Do you need a Business Register?{" "}
            <Link to="/business-register" style={{ color: "gray" }}>
              Here
            </Link>
            .
          </p>
          <p style={{ textAlign: "center", marginTop: "10px" }}>
            Already have an account?{" "}
            <Link to="/login" style={{ color: "gray" }}>
              Login here
            </Link>
            .
          </p>
          <ContinueWrapper>
            <Loginline />
            <ContinueLabel>Or CONTINUE WITH</ContinueLabel>
          </ContinueWrapper>
          <div style={{ margin: "-5px" }}>
            <SocailButtons />
          </div>
          {statusText && <Alert>{statusText}</Alert>}
        </FormContainer>
      ) : (
        <FormContainer>
          <Title>Business Registration</Title>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Label htmlFor="username">Business Name:</Label>
              <Input
                id="username"
                type="username"
                {...register("username", {
                  required: "Name is required",
                })}
              />
              {errors.username && <span>{errors.username.message}</span>}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="email">Business Email:</Label>
              <Input
                id="email"
                type="email"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: "Invalid email address",
                  },
                })}
              />
              {errors.email && <span>{errors.email.message}</span>}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="password">Password:</Label>
              <Input
                id="password"
                type="password"
                {...register("password", {
                  required: "Password is required",
                  minLength: {
                    value: 6,
                    message: "Password must be at least 6 characters",
                  },
                })}
              />
              {errors.password && <span>{errors.password.message}</span>}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="confirmPassword">Confirm Password:</Label>
              <Input
                id="confirmPassword"
                type="password"
                {...register("confirmPassword", {
                  required: "Confirm Password is required",
                  validate: (value) =>
                    value === password || "Passwords do not match",
                })}
              />
              {errors.password && <span>{errors.password.message}</span>}
            </FormGroup>
            <Button type="submit" style={{ marginTop: "15px" }}>
              Register
            </Button>
          </form>
          <p style={{ textAlign: "center", marginTop: "10px" }}>
            Do you need an individual Register?{" "}
            <Link to="/register" style={{ color: "gray" }}>
              Here
            </Link>
            .
          </p>
          <p style={{ textAlign: "center", marginTop: "10px" }}>
            Already have an account?{" "}
            <Link to="/business-login" style={{ color: "gray" }}>
              Login here
            </Link>
            .
          </p>
          {statusText && <Alert>{statusText}</Alert>}
        </FormContainer>
      )}
    </>
  );
};

export default Login;
