import { Button, Card, message, Rate, Space } from "antd";
import { EventInfo } from "../../types/model";
import { setEventRate, updateEvent } from "../../api/api";
import { useAuth } from "../../Provider/AuthContext";
import EventModal from "../Events/EventModal";
import { useEffect, useState } from "react";
import { Ticket } from "../../types/Ticket";
import {
  addPayment,
  createTicket,
  deleteTicket,
  getTickets,
  refundPayment,
  updateTicket,
} from "../../api/ticketService";
import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import TicketModal from "../Ticket/TicketModal";
import Ribbon from "antd/es/badge/Ribbon";
import PaymentModal from "../Ticket/PaymentModal";
import PaymentRefundComfirmModal from "../Ticket/PaymentRefundConfirmModal";
import ViewCustomersModal from "./ViewCustomersModal";

export interface EventDetailCardProps {
  data: EventInfo;
  onRateChange: (value: number) => void;
  onUpdated: () => void;
}

const EventDetailCard: React.FC<EventDetailCardProps> = ({
  data,
  onRateChange,
  onUpdated,
}) => {
  const { auth_type, auth, auth_business } = useAuth();
  const [modalOpen, setModalOpen] = useState(false);
  const [event, setEvent] = useState<EventInfo>(data);
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [ticketModalIsOpen, setTicketModalIsOpen] = useState(false);
  const [paymentModalIsOpen, setPaymentModalIsOpen] = useState(false);
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [viewCustomersModalOpen, setViewCustomersModalIsOpen] = useState(false);
  const [currentSelectedTicket, setCurrentSelectedTicket] = useState(-1);

  const handleUpdateEvent = async () => {
    try {
      if (event) {
        const response = await updateEvent(event);
        if (response.data) {
          onUpdated();
          message.success("Success to update the event information.");
        } else {
          message.error("Failed to update the event information.");
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setModalOpen(false);
    }
  };

  const handleGetTickets = async () => {
    try {
      if (event?.event_id) {
        const response = await getTickets(event?.event_id, auth_type);
        if (response.data) setTickets(response.data);
        console.log(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleCreateTicket = async (ticket: Ticket) => {
    try {
      const response = await createTicket(ticket);
      if (response.data) setTickets([...tickets, response.data]);
      message.success("Success to create new ticket.");
    } catch (err) {
      message.error("Failed to create new ticket.");
      console.error(err);
    }
  };

  const handleUpdateTicket = async (ticket: Ticket) => {
    try {
      if (ticket.ticket_id) {
        const response = await updateTicket(ticket.ticket_id, ticket);
        if (response.data) {
          setTickets((prev) =>
            prev.map((item) =>
              item.ticket_id === ticket.ticket_id ? response.data! : item
            )
          );
        }
      }
      message.success("Success to update the ticket.");
    } catch (err) {
      message.error("Failed to update the ticket.");
      console.error(err);
    }
  };

  const handleDeleteTicket = async (ticket_id: number) => {
    try {
      if (ticket_id) {
        const response = await deleteTicket(ticket_id);
        if (response.data)
          setTickets((prev) =>
            prev.filter((item) => item.ticket_id !== ticket_id)
          );
        message.success("Success to delete the ticket.");
      }
    } catch (err) {
      console.error(err);
      message.error("Failed to delete the ticket.");
    }
  };

  const handleAddPayment = async (paymentIntentid: string) => {
    try {
      if (
        currentSelectedTicket !== -1 &&
        tickets[currentSelectedTicket].ticket_id
      ) {
        const response = await addPayment(
          tickets[currentSelectedTicket].ticket_id!,
          paymentIntentid
        );
        if (response.data) {
          message.success("Successfully purchased ticket.");
          handleGetTickets();
        } else {
          message.error("Failed to buy this ticket.");
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleRefund = async () => {
    try {
      if (
        currentSelectedTicket !== -1 &&
        tickets[currentSelectedTicket].payment_intent_id
      ) {
        const response = await refundPayment(
          tickets[currentSelectedTicket].payment_intent_id!
        );
        if (response.status === "success") {
          message.success("Successfully refunded ticket.");
          handleGetTickets();
        } else {
          message.success("Failed to refund this ticket.");
        }
      }
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    handleGetTickets();
  }, [event]);

  const isOwner =
    auth_type === "user"
      ? auth?.user_id === data?.user_id
      : auth_business?.business_id === data?.business_id;

  return (
    <>
      <Card
        style={{ minWidth: "300px", margin: "10px 20px", maxWidth: "40vw" }}
        bordered={false}
        title={data.name}
        extra={
          isOwner ? (
            <Button
              key="edit"
              onClick={(event) => {
                event.stopPropagation();
                setEvent(data);
                setModalOpen(true);
              }}
              shape="circle"
              icon={<EditOutlined />}
            />
          ) : undefined
        }
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            justifyContent: "center",
          }}
        >
          <Card.Meta title={data.location} description={data.description} />
          <Rate
            style={{ margin: "0 auto" }}
            value={data.rating}
            onChange={(value) => {
              setEventRate({ eventId: data.event_id!, rating: value });
              onRateChange(value);
            }}
            disabled={!isOwner}
          />
          <Space style={{ overflow: "auto", padding: "10px", gap: "10px" }}>
            {tickets.map((ticket, index) => {
              const ticketCard = (
                <Card
                  size="small"
                  key={index}
                  title={"Ticket " + (index + 1)}
                  style={{ height: "100%", width: "170px" }}
                  actions={
                    isOwner
                      ? [
                          <EyeOutlined
                            onClick={() => {
                              setViewCustomersModalIsOpen(true);
                              setCurrentSelectedTicket(index);
                            }}
                          />,
                          <EditOutlined
                            onClick={() => {
                              setTicketModalIsOpen(true);
                              setCurrentSelectedTicket(index);
                            }}
                          />,
                          <DeleteOutlined
                            onClick={() => {
                              if (ticket.ticket_id)
                                handleDeleteTicket(ticket.ticket_id);
                            }}
                          />,
                        ]
                      : undefined
                  }
                >
                  <div
                    style={{
                      textAlign: "center",
                      color: "red",
                      fontSize: "20px",
                    }}
                  >
                    ${ticket.price}
                  </div>
                  <div
                    style={{
                      wordBreak: "break-all",
                      minHeight: "100px",
                      overflow: "auto",
                    }}
                  >
                    {ticket?.description}
                  </div>
                  <div
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {!isOwner && (
                      <Button
                        style={{ padding: "6px 12px" }}
                        size="small"
                        danger={!!ticket.payment_intent_id}
                        type="primary"
                        onClick={() => {
                          setCurrentSelectedTicket(index);
                          if (ticket.payment_intent_id === "") {
                            setPaymentModalIsOpen(true);
                          } else {
                            setConfirmModalIsOpen(true);
                          }
                        }}
                      >
                        {ticket.payment_intent_id === "" ? "Buy" : "Refund"}
                      </Button>
                    )}
                  </div>
                </Card>
              );

              if (ticket.type === "common") {
                return ticketCard;
              }
              return (
                <Ribbon
                  key={"Ribbon" + index}
                  text={ticket.type === "early_bird" ? "Early Bird" : "VIP"}
                  color={ticket.type === "early_bird" ? "blue" : "red"}
                >
                  {ticketCard}
                </Ribbon>
              );
            })}
          </Space>
          {isOwner && (
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setTicketModalIsOpen(true)}
            >
              Create Ticket
            </Button>
          )}
        </div>
      </Card>
      <EventModal
        open={modalOpen}
        onOk={() => handleUpdateEvent()}
        onCancel={() => setModalOpen(false)}
        name={event?.name || ""}
        location={event?.location || ""}
        begin_at={event?.begin_at}
        end_at={event?.end_at}
        description={event?.description || ""}
        onChangeName={(name: string) => setEvent({ ...event, name })}
        onChangeDescription={(description: string) =>
          setEvent({ ...event, description })
        }
        onBeginAt={(date: Date) => setEvent({ ...event, begin_at: date })}
        onEndAt={(date: Date) => setEvent({ ...event, end_at: date })}
      />
      {event?.event_id && (
        <TicketModal
          event_id={event?.event_id}
          open={ticketModalIsOpen}
          onOk={async (ticket: Ticket) => {
            if (currentSelectedTicket === -1) {
              await handleCreateTicket(ticket);
            } else {
              await handleUpdateTicket(ticket);
            }
            setTicketModalIsOpen(false);
          }}
          onCancel={() => setTicketModalIsOpen(false)}
          data={
            currentSelectedTicket === -1
              ? undefined
              : tickets[currentSelectedTicket]
          }
          type={currentSelectedTicket === -1 ? "create" : "edit"}
        />
      )}
      <PaymentModal
        open={paymentModalIsOpen}
        onOk={async (paymentIntentId: string) => {
          await handleAddPayment(paymentIntentId);
          setPaymentModalIsOpen(false);
        }}
        onCancel={() => setPaymentModalIsOpen(false)}
        ticket_id={
          currentSelectedTicket !== -1
            ? tickets[currentSelectedTicket].ticket_id
            : undefined
        }
        quantity={1}
      />
      <PaymentRefundComfirmModal
        open={confirmModalIsOpen}
        onOk={async () => {
          await handleRefund();
          setConfirmModalIsOpen(false);
        }}
        onCancel={() => setConfirmModalIsOpen(false)}
      />
      <ViewCustomersModal
        open={viewCustomersModalOpen}
        onOk={() => {
          setViewCustomersModalIsOpen(false);
        }}
        onCancel={() => {
          setViewCustomersModalIsOpen(false);
        }}
        data={
          currentSelectedTicket !== -1 &&
          tickets[currentSelectedTicket].usernames
            ? tickets[currentSelectedTicket].usernames
            : []
        }
      />
    </>
  );
};

export default EventDetailCard;
