import React from "react";
import { io, Socket } from "socket.io-client";
const API_URL = `${process.env.REACT_APP_WS_URL || "https://api.nook.today"}`;
console.log(`API URL: ${API_URL}`);

export const socket: Socket = io(API_URL, {
  path: "/ws/socket.io",
  transports: ["websocket"],
  withCredentials: true,
});

export const SocketContext = React.createContext(socket);
