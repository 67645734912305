import axios from "axios";
import {
  DeleteResponse,
  Forum,
  ForumResponse,
  ForumsResponse,
} from "../types/Forum";

const API_URL = `${process.env.REACT_APP_API_URL || "https://api.nook.today"}/api/forum`;
const getToken = () => localStorage.getItem("token");

export const getForums = async (event_id: any): Promise<ForumsResponse> => {
  const response = await axios.get(`${API_URL}/${event_id}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const createForum = async (forum: Forum): Promise<ForumResponse> => {
  const response = await axios.post(`${API_URL}/create`, forum, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const updateForum = async (
  forum_id: any,
  forum: Forum
): Promise<ForumResponse> => {
  const response = await axios.put(`${API_URL}/${forum_id}`, forum, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const deleteForum = async (forum_id: any): Promise<DeleteResponse> => {
  const response = await axios.delete(`${API_URL}/${forum_id}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const sendBroadCastMessage = async (
  message: string,
  event_id: string
): Promise<{
  data?: boolean;
  error_message?: string;
}> => {
  const response = await axios.post(
    `${API_URL}/broadcast`,
    {
      message,
      event_id,
    },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};
