import { createContext, ReactNode, useState, useContext } from "react";
import { EventInfo } from "../types/model";
interface data {
    event: EventInfo | undefined;
    setEvent: React.Dispatch<React.SetStateAction<EventInfo | undefined>>;
}
const DataContext = createContext<data | undefined>(undefined);

const DataProvider = ({ children }: { children: ReactNode }) => {
    const [event, setEvent] = useState<EventInfo | undefined>(undefined);

    return (
        <DataContext.Provider value={{ event, setEvent }}>{children}</DataContext.Provider>
    )
}
const useData = () => {
    const context = useContext(DataContext);
    if (!context) {
        throw new Error('useData must be used within an DataProvider');
    }
    return context;
}
export { DataProvider, useData }