import React, { useEffect } from "react";
import { notification, ConfigProvider } from "antd";
import { motion } from "framer-motion";
import {
  HomePageContainer,
  Heading,
  SubHeading,
  ContactLink,
  StyledText,
  StyledFeatureList,
  StyledFeatureItem,
} from "./Pages.styles";

import stockImage from "../assets/stock_image_nook.png";
import nookLogo from "../assets/nook_app.png";

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.3,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

const HomePage: React.FC = () => {
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    api.info({
      message: "Welcome to Nook!",
      description: "Discover what makes Nook special!",
    });
  }, [api]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorBgContainer: "#8CA375",
          },
          Select: {
            optionSelectedBg: "#8ca375",
            colorBgContainer: "#202a16",
            colorText: "#BEC2BA",
            colorBorder: "#5A4803",
          },
        },
      }}
    >
      <HomePageContainer
        as={motion.div}
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        {contextHolder}

        <Heading as={motion.h1} variants={itemVariants}>
          About Nook
        </Heading>

        <StyledText as={motion.p} variants={itemVariants}>
          At Nook, we're on a mission to bridge the gap between digital and real-world connections. In a world where interactions are often confined to screens, we believe in the power of shared experiences and the magic that happens when like-minded people come together.
        </StyledText>

        <StyledText as={motion.p} variants={itemVariants}>
          Nook is your go-to platform for discovering and enhancing social experiences. Whether you’re looking for the perfect event venue or hoping to meet new people with similar interests, Nook makes it easier than ever to connect. Our features are designed to help you build connections that last—starting before you even step out the door.
        </StyledText>

        <SubHeading as={motion.h2} variants={itemVariants}>
          Why Nook?
        </SubHeading>

        <StyledFeatureList as={motion.ul} variants={containerVariants}>
          <StyledFeatureItem as={motion.li} variants={itemVariants}>
            <strong>Discover Local Venues:</strong> Find the best event spots in your area.
          </StyledFeatureItem>
          <StyledFeatureItem as={motion.li} variants={itemVariants}>
            <strong>Connect Before You Go:</strong> Engage with fellow attendees and build relationships before the event even begins.
          </StyledFeatureItem>
          <StyledFeatureItem as={motion.li} variants={itemVariants}>
            <strong>Integrated Social Media:</strong> Seamlessly connect your profiles to make staying in touch a breeze.
          </StyledFeatureItem>
        </StyledFeatureList>

        {/* Add the images below the Integrated Social Media point */}
        <motion.div
          variants={itemVariants}
          style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}
        >
          <img src={stockImage} alt="Stock" style={{ width: "300px", marginRight: "20px" }} />
          <img src={nookLogo} alt="Nook Logo" style={{ width: "150px" }} />
        </motion.div>

        <StyledText
          as={motion.p}
          variants={itemVariants}
          style={{ textAlign: "center", marginTop: "2rem" }}
        >
          Have questions or suggestions? We'd love to hear from you! Contact us at{" "}
          <ContactLink href="mailto:support@nook.today">
            support@nook.today
          </ContactLink>
          .
        </StyledText>
      </HomePageContainer>
    </ConfigProvider>
  );
};

export default HomePage;
