import axios from "axios";
import {
  DeleteResponse,
  Message,
  MessageResponse,
  MessagesResponse,
} from "../types/Forum";
import { User } from "../types/model";

const API_URL = `${
  process.env.REACT_APP_API_URL || "https://api.nook.today"
}/api/message`;
const getToken = () => localStorage.getItem("token");

export const getMessagesByForum = async (
  id: any
): Promise<MessagesResponse> => {
  const response = await axios.get(`${API_URL}/forum/${id}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const getMessagesByMember = async (
  user_id: any
): Promise<MessagesResponse> => {
  const response = await axios.get(`${API_URL}/member/${user_id}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const createMessage = async (
  message: Message
): Promise<MessageResponse> => {
  const response = await axios.post(`${API_URL}/create`, message, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const updateMessage = async (
  message_id: any,
  message: Message
): Promise<MessageResponse> => {
  const response = await axios.put(`${API_URL}/${message_id}`, message, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const deleteMessage = async (
  message_id: any
): Promise<DeleteResponse> => {
  const response = await axios.delete(`${API_URL}/${message_id}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};
