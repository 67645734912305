import { useEffect, useState } from "react";
import { SignupResponse } from "../../types/auth";
import RightSidebar from "../../Components/Events/RightSidebar";
import FriendItem from "../../Components/Feed/FriendItem";
import { getAllEvent, getFriends, getFriendsPosts } from "../../api/api";
import EventCard from "../../Components/Events/EventCard";
import { EventInfo, Post } from "../../types/model";
import { useNavigate } from "react-router-dom";
import ProfileView from "../../Components/UserProfile/ProfileView";
import { useSearch } from "../../Provider/SearchContext";
import PostItem from "../../Components/Feed/PostItem";

const FeedPage = () => {
  const { searchText } = useSearch();
  const [friends, setFriends] = useState<SignupResponse[]>([]);
  const [friendPosts, setFriendPosts] = useState<Post[]>([]);
  const [loading, setLoading] = useState(false);
  const [viewProfile, setViewProfile] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [events, setEvents] = useState<EventInfo[]>([]);
  const navigate = useNavigate();
  const handleGetFriends = async () => {
    try {
      const response = await getFriends();
      if (response.data) {
        let friends = response.data;
        if (searchText) {
          friends = friends.filter((friend) =>
            friend.username?.toLowerCase().includes(searchText.toLowerCase())
          );
        }
        setFriends(friends);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleGetFriendsPosts = async () => {
    try {
      const response = await getFriendsPosts();
      if (response.data) {
        setFriendPosts(response.data);
      }
    } catch (error) {
      console.error("Failed to fetch posts:", error);
    }
  };

  const __getAllEvents = async () => {
    try {
      const response = await getAllEvent("user");
      if (response.data) {
        let events = response.data;
        if (searchText) {
          events = events.filter((event) =>
            event.name?.toLowerCase().includes(searchText.toLowerCase())
          );
        }
        setEvents(events);
      }
    } catch (error) {
      console.error("Failed to fetch events:", error);
    }
  };

  const handleGetData = async () => {
    try {
      setLoading(true);
      handleGetFriends();
      handleGetFriendsPosts();
      __getAllEvents();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetData();
  }, [searchText]);

  return (
    <div style={{ display: "flex", flexDirection: "row", gap: "12px" }}>
      <div
        style={{
          display: "flex",
          flex: "1",
          gap: "16px",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#202A16",
            gap: "10px",
            padding: "16px 12px",
            overflow: "auto",
            borderRadius: "16px",
            height: "128px",
          }}
        >
          {friends.length ? friends.map((friend, index) => (
            <FriendItem
              data={friend}
              key={index}
              onClick={() => {
                setViewProfile(true);
                setProfileData(friend);
              }}
            />
          )) : <div style={{ fontSize: "14px", padding: "30px" }} >There are no friends present.</div>}
        </div>
        {events.length ? events.map((event, index) => (
          <EventCard
            key={index}
            event_id={event.event_id!}
            name={event.name!}
            description={event.description!}
            location={event.location!}
            begin_at={event.begin_at!}
            end_at={event.end_at!}
            rating={event.rating}
            onClick={() => navigate(`/event/${event.event_id}`)}
          />
        )) : <div style={{ fontSize: "14px", backgroundColor: "#202a16", padding: "40px", borderRadius: "16px" }}>There are no events for you.</div>}
        <div style={{ backgroundColor: "#202A16", padding: "16px", borderRadius: "16px" }}>
          {friendPosts.length ? friendPosts.map((post, index) => (
            <PostItem
              key={index}
              content={post.content}
              username={post.username} 
              createdAt={post.created_at}
            />
          )) : <div style={{ fontSize: "14px", padding: "20px" }}>No posts from friends.</div>}
        </div>
      </div>
      <RightSidebar />
      <ProfileView
        isOpen={viewProfile}
        onClose={() => setViewProfile(false)}
        data={profileData}
      />
    </div>
  );
};

export default FeedPage;
