import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Modal } from "antd";
import { useEffect, useState } from "react";
import { createPayment } from "../../api/ticketService";
import CheckoutForm from "./CheckoutForm";

export interface PaymentModalProps {
  open: boolean;
  onOk: (paymentIntentId: string) => void;
  onCancel: () => void;
  ticket_id: number | undefined;
  quantity: number;
}

const PaymentModal: React.FC<PaymentModalProps> = ({
  open,
  onOk,
  onCancel,
  ticket_id,
  quantity,
}) => {
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "pk_test_51PkNyGRoMLPC6yHCvSr0hyL4VxWH4EDVd2JwztQ8WUCJRkzoVxvXbtz3vd0X9Z545nqAUlPx9CzKF4iJyiWWDK5M00DbXhU7Qt"
  );

  const [clientSecret, setClientSecret] = useState("");
  const [loading, setLoading] = useState(false);

  const handleGetClientSecret = async () => {
    try {
      if (ticket_id) {
        setLoading(true);
        const response = await createPayment(ticket_id, quantity);
        setClientSecret(response.client_secret);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetClientSecret();
  }, [ticket_id]);

  return (
    <Modal
      open={open}
      footer={[]}
      onCancel={onCancel}
      title="Payment"
      style={{ minHeight: "400px" }}
      loading={loading}
    >
      {clientSecret && (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret,
          }}
        >
          <CheckoutForm
            onCancel={onCancel}
            onSubmit={() => {
              const [paymentIntentId] = clientSecret.split("_secret");
              onOk(paymentIntentId);
            }}
          />
        </Elements>
      )}
    </Modal>
  );
};

export default PaymentModal;
