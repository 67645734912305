// ProtectedRouteBusiness.tsx
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useAuth } from "../Provider/AuthContext";
import { useNavigate } from "react-router-dom";
const ProtectedRouteBusiness: React.FC = () => {
  const { auth_business } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (auth_business != null)
      if (auth_business.access_token == null) navigate("/business-login");
  }, [auth_business]);
  return <Outlet />;
};

export default ProtectedRouteBusiness;
