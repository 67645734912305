import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getAllEvent } from "../../api/api";
import Card from "./EventCard";
import { EventInfo } from "../../types/model";
import { Loader } from "@googlemaps/js-api-loader";
import { SocketContext } from "../../Provider/SocketContext";
import useMapAccessToken from "../../hooks/useMapAccessToken";
import { calculateProximity } from "../../utils/CalcHelpers";
import {
  DatePicker,
  InputNumber,
  Select,
  Space,
  Button,
  Row,
  Col,
  ConfigProvider,
} from "antd";
import dayjs, { Dayjs } from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { useAuth } from "../../Provider/AuthContext";
import { useSearch } from "../../Provider/SearchContext";

const { Option } = Select;
const { RangePicker } = DatePicker;
dayjs.extend(isBetween);

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-end;
`;

const SearchBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #202a16;
  border-radius: 16px;
  padding: 16px 24px;
  gap: 20px;
  width: 100%;
`;

const getCurrentLocation = (): Promise<GeolocationPosition> => {
  return new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      reject(new Error("Geolocation is not supported by your browser"));
    } else {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    }
  });
};

const ProfilePanel: React.FC = () => {
  const { searchText } = useSearch();
  const today = dayjs();
  const oneMonthAgo = today.subtract(1, "month");
  const socket = useContext(SocketContext);
  const [mapAccessToken] = useMapAccessToken(socket);
  const [loader, setLoader] = useState<Loader | null>(null);
  const [events, setEvents] = useState<Array<EventInfo>>([]);
  const [filteredEvents, setFilteredEvents] = useState<Array<EventInfo>>([]);
  const [distance, setDistance] = useState<number>(5);
  const [distanceType, setDistanceType] = useState<"K" | "M">("K");
  const [placeType, setPlaceType] = useState<string>("all");
  const [dates, setDates] = useState<[Dayjs | null, Dayjs | null]>([
    oneMonthAgo,
    today,
  ]);
  const [currentLocation, setCurrentLocation] =
    useState<GeolocationPosition | null>(null);
  const { auth_type, auth } = useAuth();

  const navigate = useNavigate();

  const __getAllEvents = async () => {
    try {
      const response = await getAllEvent(auth_type);
      if (response.data) {
        let events = response.data;
        if (searchText && auth?.access_token) {
          events = events.filter((event) =>
            event.name?.toLowerCase().includes(searchText.toLowerCase())
          );
        }
        setEvents(events);
      }
    } catch (error) {
      console.error("Failed to fetch events:", error);
    }
  };

  useEffect(() => {
    __getAllEvents();
    getCurrentLocation().then(setCurrentLocation).catch(console.error);
  }, [searchText]);

  useEffect(() => {
    if (mapAccessToken && !loader) {
      setLoader(
        new Loader({
          apiKey: mapAccessToken,
          version: "weekly",
          libraries: ["places"],
        })
      );
    }
  }, [mapAccessToken, loader]);

  useEffect(() => {
    if (!currentLocation || !loader) return;

    const userLat = currentLocation.coords.latitude;
    const userLon = currentLocation.coords.longitude;

    const getPlaceDetails = async (
      placeId: string
    ): Promise<{
      latitude: number;
      longitude: number;
      placeTypes?: Array<string>;
    }> => {
      if (!loader) throw new Error("Google Maps Loader is not initialized");

      await loader.load();

      const service = new google.maps.places.PlacesService(
        document.createElement("div")
      );

      return new Promise((resolve, reject) => {
        service.getDetails({ placeId }, (place, status) => {
          if (
            status === google.maps.places.PlacesServiceStatus.OK &&
            place?.geometry
          ) {
            resolve({
              latitude: place?.geometry?.location?.lat() || 0,
              longitude: place?.geometry?.location?.lng() || 0,
              placeTypes: place.types,
            });
          } else {
            reject(`Failed to fetch place details: ${status}`);
          }
        });
      });
    };

    const fetchAndFilterEvents = async () => {
      try {
        const eventDetailsPromises = events.map(async (event) => {
          const placeDetails = await getPlaceDetails(event.location!);
          return { ...event, ...placeDetails };
        });

        const eventsWithDetails = await Promise.all(eventDetailsPromises);

        const filtered = eventsWithDetails.filter((event) => {
          const eventDistance = calculateProximity(
            userLat,
            userLon,
            event.latitude!,
            event.longitude!,
            distanceType
          );
          const isWithinDistance = eventDistance <= distance;
          const isOfType =
            placeType === "all" || event.placeTypes!.includes(placeType);
          const isInDateRange =
            dates &&
            dayjs(event.created_at).isBetween(dates[0], dates[1], "day", "[]");
          return isWithinDistance && isOfType && isInDateRange;
        });

        setFilteredEvents(filtered);
      } catch (error) {
        console.error("Failed to fetch and filter events:", error);
      } finally {
      }
    };

    fetchAndFilterEvents();
  }, [
    events,
    distance,
    placeType,
    dates,
    currentLocation,
    loader,
    distanceType,
  ]);

  return (
    <Root>
      <SearchBarContainer>
        <ConfigProvider
          componentSize="large"
          theme={{
            components: {
              Select: {
                optionSelectedBg: "#8ca375",
                colorBgContainer: "#202a16",
                colorText: "#BEC2BA",
                colorBorder: "#5A4803",
              },
              InputNumber: {
                colorBgContainer: "#202a16",
                colorTextPlaceholder: "#BEC2BA",
                colorText: "#BEC2BA",
                colorBorder: "#5A4803",
              },
              DatePicker: {
                colorBgContainer: "#202a16",
                colorText: "#BEC2BA",
                colorBorder: "#5A4803",
              },
            },
          }}
        >
          <Space>
            <div>Locations within:</div>
            <InputNumber
              min={1}
              value={distance}
              onChange={(value) => setDistance(value!)}
              addonAfter={
                <Select
                  value={distanceType}
                  onChange={(value) => setDistanceType(value)}
                >
                  <Option value="K">km</Option>
                  <Option value="M">mile</Option>
                </Select>
              }
              defaultValue={100}
            />
          </Space>
          <Space>
            <div>Place type:</div>
            <Select
              value={placeType}
              onChange={(value) => setPlaceType(value)}
              style={{ width: 200 }}
            >
              <Option value="all">All</Option>
              <Option value="bar">Bars</Option>
              <Option value="restaurant">Restaurants</Option>
              <Option value="night_club">Clubs</Option>
              <Option value="concert_hall">Concerts</Option>
              <Option value="movie_theater">Shows</Option>
              <Option value="theater">Theaters</Option>
            </Select>
          </Space>
          <Space>
            <div>Date Range:</div>
            <RangePicker
              value={dates}
              onChange={(dates: [Dayjs | null, Dayjs | null] | null) =>
                setDates(dates!)
              }
              format="MM/DD/YYYY"
              disabledDate={(current: Dayjs) => {
                return current && current > today.endOf("day");
              }}
            />
          </Space>
          <Button type="primary">Search Event</Button>
        </ConfigProvider>
      </SearchBarContainer>
      <Row
        gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}
        style={{
          width: "100%",
          margin: 0,
        }}
      >
        {filteredEvents.map((event, index) => (
          <Col key={event.event_id} xs={24} sm={12} md={12} lg={12}>
            <Card
              key={index}
              event_id={event.event_id!}
              name={event.name!}
              description={event.description!}
              location={event.location!}
              begin_at={event.begin_at!}
              end_at={event.end_at!}
              rating={event.rating}
              onClick={() => navigate(`/event/${event.event_id}`)}
            />
          </Col>
        ))}
      </Row>
    </Root>
  );
};

export default ProfilePanel;
