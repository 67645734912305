import { useState, useEffect, Dispatch, SetStateAction } from 'react';

const useSessionStorageState = <T,>(
  key: string,
  initialValue: T
): [T, Dispatch<SetStateAction<T>>] => {
  const storedValue = sessionStorage.getItem(key);
  const initial = storedValue !== "undefined" && storedValue ? JSON.parse(storedValue) : initialValue;

  const [value, setValue] = useState<T>(initial);

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

export default useSessionStorageState;
  