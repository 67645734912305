import React, { useEffect, useState } from "react";
import { Card, Col, Row, Statistic, Table, Space } from "antd";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { DiscountUsage, DiscountStats } from "../../types/Discount";
import { getDiscountUsages, getDiscountsStats } from "../../api/discountService";

import styled from "styled-components";
const Panel = styled.div`
  height: 100%;
  padding: 40px;
  background-color: #202A16;
  border-radius: 16px;
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: start;
`;
const Dashboard: React.FC = () => {
  const [discountUsages, setDiscountUsages] = useState<DiscountUsage[]>([]);
  const [discountStats, setDiscountStats] = useState<DiscountStats[]>([]);

  const getData = async () => {
    const { data } = await getDiscountUsages();
    if (Array.isArray(data)) {
      setDiscountUsages(data);
    }
    const { data: stats } = await getDiscountsStats();
    if (Array.isArray(stats)) {
      setDiscountStats(stats);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    { title: "Discount ID", dataIndex: "discount_id", key: "discount_id" },
    { title: "User ID", dataIndex: "user_id", key: "user_id" },
    { title: "Event ID", dataIndex: "event_id", key: "event_id" },
    {
      title: "Usage Time",
      dataIndex: "created_at",
      key: "created_at",
    },
  ];

  return (
    <Panel>
      <h1>Dashboard</h1>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Row gutter={24}>
          <Col span={12}>
            <Card>
              <Statistic
                title="Total Discounts Used"
                value={discountUsages.length}
              />
            </Card>
          </Col>
          <Col span={12}>
            <Card>
              <Statistic
                title="Unique Discounts"
                value={discountStats.length}
              />
            </Card>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 20 }}>
          <Col span={24}>
            <Card title="Discount Usage Details">
              <Table
                dataSource={discountUsages}
                columns={columns}
                rowKey="usage_id"
              />
            </Card>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 20 }}>
          <Col span={24}>
            <Card title="Discount Usage Statistics">
              <ResponsiveContainer width="100%" height={400}>
                <BarChart data={discountStats}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="discount_id" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="usage_count" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </Card>
          </Col>
        </Row>
      </Space>
    </Panel>
  );
};

export default Dashboard;
