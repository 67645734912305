import React from "react";
import {
  AboutPageContainer,
  Heading,
  SubHeading,
  ContactLink,
  StyledText,
  StyledFeatureList,
  StyledFeatureItem,
} from "./Pages.styles";

const fadeIn = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8, delay: 0.2 } },
};

const staggerContainer = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.3,
    },
  },
};

const AboutPage: React.FC = () => {
  return (
    <AboutPageContainer initial="hidden" animate="visible" variants={fadeIn}>
      <Heading variants={fadeIn}>About Nook</Heading>
      <StyledText variants={fadeIn}>
        At Nook, we're on a mission to bridge the gap between digital and
        real-world connections. In a world where interactions are often confined
        to screens, we believe in the power of shared experiences and the magic
        that happens when like-minded people come together.
      </StyledText>
      <StyledText variants={fadeIn}>
        Nook is your go-to platform for discovering and enhancing social
        experiences. Whether you’re looking for the perfect event venue or
        hoping to meet new people with similar interests, Nook makes it easier
        than ever to connect. Our features are designed to help you build
        connections that last—starting before you even step out the door.
      </StyledText>

      <SubHeading variants={fadeIn}>Why Nook?</SubHeading>
      <StyledFeatureList variants={staggerContainer}>
        <StyledFeatureItem variants={fadeIn}>
          <strong>Discover Local Venues:</strong> Find the best event spots in
          your area.
        </StyledFeatureItem>
        <StyledFeatureItem variants={fadeIn}>
          <strong>Connect Before You Go:</strong> Engage with fellow attendees
          and build relationships before the event even begins.
        </StyledFeatureItem>
        <StyledFeatureItem variants={fadeIn}>
          <strong>Integrated Social Media:</strong> Seamlessly connect your
          profiles to make staying in touch a breeze.
        </StyledFeatureItem>
      </StyledFeatureList>

      <SubHeading variants={fadeIn}>Key Features</SubHeading>
      <StyledFeatureList variants={staggerContainer}>
        <StyledFeatureItem variants={fadeIn}>
          Find event venues in your area.
        </StyledFeatureItem>
        <StyledFeatureItem variants={fadeIn}>
          Connect with others attending the same events.
        </StyledFeatureItem>
        <StyledFeatureItem variants={fadeIn}>
          Make connections before stepping out.
        </StyledFeatureItem>
        <StyledFeatureItem variants={fadeIn}>
          Integrated social media profiles for easy engagement.
        </StyledFeatureItem>
      </StyledFeatureList>

      <StyledText
        variants={fadeIn}
        style={{ textAlign: "center", marginTop: "2rem" }}
      >
        Have questions or suggestions? We'd love to hear from you! Contact us at{" "}
        <ContactLink href="mailto:support@nook.today">
          support@nook.today
        </ContactLink>
        .
      </StyledText>
    </AboutPageContainer>
  );
};

export default AboutPage;
