import DiscountsList from "../../Components/Discounts/DiscountList";
import styled from "styled-components";

const Panel = styled.div`
  height: 100%;
  padding: 40px;
  background-color: #202A16;
  border-radius: 16px;
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: start;
`;

const DiscountPage = () => {
  return (
    <Panel>
      <h1>Discounts</h1>
      <DiscountsList />
    </Panel>
  );
};

export default DiscountPage;
