import { Button, ConfigProvider } from "antd";
import { Message, Forum } from "../../types/Forum";
import { useContext, useEffect, useRef, useState } from "react";
import MessageItem from "../Forum/MessageItem";
import { ClearOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import {
  // createMessage,
  deleteMessage,
  getMessagesByForum,
  getMessagesByMember,
  updateMessage,
} from "../../api/messageService";
import { useAuth } from "../../Provider/AuthContext";
import { SocketContext } from "../../Provider/SocketContext";

export interface MessageDetailProps {
  data?: Forum;
  memberId?: number;
}

const MessageDetail: React.FC<MessageDetailProps> = ({ data, memberId }) => {
  const [messageHistory, setMessageHistory] = useState<Message[]>([]);
  const [message, setMessage] = useState("");
  const endOfMessagesRef = useRef<HTMLDivElement>(null);
  const [selectedMessage, setSelectedMessage] = useState(-1);
  const { auth_type, auth } = useAuth();
  const socket = useContext(SocketContext);

  const handleAddMessage = (data: Message) => {
    setMessageHistory((prev) => [...prev, data]);
    if (endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    socket.on("receiveMessage", (data: Message) => {
      console.log("Message", data);
      handleAddMessage(data);
    });
    socket.on("sendMessage:response", (data: Message) => {
      setMessage("");
      handleAddMessage(data);
    });
  }, [socket]);

  const handleGetMessageHistoryByForumId = async (forum_id: number) => {
    try {
      const response = await getMessagesByForum(forum_id);
      if (response.data) setMessageHistory(response.data);
    } catch (err) {
      console.error(err);
    }
  };

  const handleGetMessageHistoryByMemberId = async (member_id: number) => {
    try {
      const response = await getMessagesByMember(member_id);
      if (response.data) setMessageHistory(response.data);
    } catch (err) {
      console.error(err);
    }
  };

  const handleCreateMessage = async () => {
    try {
      if (message === "") {
        return;
      }
      const messageData: Message = {
        content: message,
        user_id: auth?.user_id,
      };
      if (memberId) {
        messageData.user2_id = memberId;
      }
      if (data) {
        messageData.forum_id = data.forum_id;
      }
      console.log(messageData);
      socket.emit("sendMessage", messageData);
    } catch (err) {
      console.error(err);
    }
  };

  const handleUpdateMessage = async () => {
    try {
      const updatedMessage: Message = {
        ...messageHistory[selectedMessage],
        content: message,
      };
      const response = await updateMessage(
        updatedMessage.message_id,
        updatedMessage
      );
      if (response.data) {
        setMessageHistory((prev) =>
          prev.map((item, index) =>
            index === selectedMessage ? updatedMessage : item
          )
        );
        setSelectedMessage(-1);
        setMessage("");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleDeleteMessage = async (index: number) => {
    try {
      if (messageHistory[index].message_id) {
        const response = await deleteMessage(messageHistory[index].message_id);
        if (response.data) {
          setMessageHistory((prev) => prev.filter((_, id) => id !== index));
        }
      }
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    if (data?.forum_id) {
      handleGetMessageHistoryByForumId(data?.forum_id);
    }

    if (memberId && memberId != -1) {
      handleGetMessageHistoryByMemberId(memberId);
    }
  }, [data, memberId]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <div
        style={{
          height: "calc(100vh - 470px)",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          overflow: "auto",
          padding: "10px 10px 70px 10px",
        }}
      >
        {data && (
          <div
            style={{ borderBottom: "1px solid #ccc", paddingBottom: "20px" }}
          >
            {data.content}
          </div>
        )}
        {messageHistory.map((message, index) => (
          <div
            style={{
              display: data ? "block" : "flex",
              justifyContent:
                message.user_id === auth?.user_id ? "flex-end" : "flex-start",
              paddingLeft: data ? "10px" : "50px",
              paddingRight: data ? "10px" : "100px",
            }}
            key={index}
          >
            <MessageItem
              data={message}
              onUpdate={() => {
                setMessage(message.content);
                setSelectedMessage(index);
              }}
              onDelete={() => handleDeleteMessage(index)}
              selected={index === selectedMessage}
            />
          </div>
        ))}
        <div ref={endOfMessagesRef} />
      </div>
      {auth_type === "user" && (
        <ConfigProvider
          theme={{
            components: {
              Button: {
                colorBgContainerDisabled: "#8CA375",
              },
            },
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              width: "100%",
              zIndex: "10",
              borderTop: "1px solid #ccc",
              padding: "20px",
            }}
          >
            <TextArea
              placeholder="Send a message..."
              disabled={!data && !memberId}
              value={message}
              allowClear={{
                clearIcon: (
                  <ClearOutlined onClick={() => setSelectedMessage(-1)} />
                ),
              }}
              onChange={(event) => setMessage(event.target.value)}
              onKeyDown={(event) => {
                if (
                  event.code === "Enter" &&
                  !event.shiftKey &&
                  !event.ctrlKey
                ) {
                  event.preventDefault();
                  if (selectedMessage === -1) {
                    handleCreateMessage();
                  } else {
                    handleUpdateMessage();
                  }
                } else if (event.code === "Escape" && selectedMessage !== -1) {
                  event.stopPropagation();
                  setSelectedMessage(-1);
                  setMessage("");
                }
              }}
              style={{ resize: "none", height: data ? "50px" : "120px" }}
            />
            <Button
              type="primary"
              disabled={!message.length}
              onClick={() => {
                if (selectedMessage === -1) {
                  handleCreateMessage();
                } else {
                  handleUpdateMessage();
                }
              }}
            >
              {selectedMessage === -1 ? "Send" : "Update"}
            </Button>
          </div>
        </ConfigProvider>
      )}
    </div>
  );
};

export default MessageDetail;
