import React from "react";
import "./navbar.css";
import { useAuth } from "../Provider/AuthContext";
import NavbarItem from "../Components/Navbar/NavbarItem";
import HomeItem from "../Components/Navbar/HomeItem";
import BottomNavbarItem from "../Components/Navbar/BottomNavbarItem";

import { CalendarOutlined, DashboardOutlined } from "@ant-design/icons";

import FeedIcon from "../assets/icons/feed.svg";
import SearchIcon from "../assets/icons/search.svg";
import CalendarIcon from "../assets/icons/calendar.svg";
import PeopleIcon from "../assets/icons/people.svg";
import ChatIcon from "../assets/icons/chat.svg";
import MatchIcon from "../assets/icons/match.svg";
import Calendar2Icon from "../assets/icons/calendar2.svg";

const Navbar: React.FC = () => {
  const { auth, auth_business } = useAuth();

  const userNavbarData = [
    {
      text: "Feed",
      path: "/feed",
      icon: FeedIcon,
    },
    {
      text: "Discover Events",
      path: "/events",
      icon: SearchIcon,
    },
    {
      text: "Host an Event",
      path: "/create-event",
      icon: CalendarIcon,
    },
    {
      text: "My Connections",
      path: "/connections",
      icon: PeopleIcon,
    },
    {
      text: "Chats",
      path: "/chats",
      icon: ChatIcon,
    },
    {
      text: "Matchmaking",
      path: "/matchmaking",
      icon: MatchIcon,
    },
    {
      text: "RSVPs",
      path: "/rsvps",
      icon: Calendar2Icon,
    },
    // {
    //   text: "Settings",
    //   path: "/settings",
    //   icon: <SettingOutlined />,
    // },
  ];

  const businessNavbarData = [
    {
      text: "Dashboard",
      path: "/business/dashboard",
      icon: <DashboardOutlined style={{ fontSize: "24px" }} />,
    },
    {
      text: "My Events",
      path: "/business/events",
      icon: Calendar2Icon,
    },
    {
      text: "Host an Event",
      path: "/business/create-event",
      icon: CalendarIcon,
    },
    {
      text: "Discounts",
      path: "/business/discounts",
      icon: <CalendarOutlined style={{ fontSize: "24px" }} />,
    },
    // {
    //   text: "Setting",
    //   path: "/business/settings",
    //   icon: <SettingOutlined />,
    // },
    // {
    //   text: "About",
    //   path: "/about",
    //   icon: <InfoCircleOutlined />,
    // },
  ];

  return (
    <div className="nav-container">
      <div style={{ display: "flex", flexDirection: "column", gap: "48px" }}>
        <HomeItem key="home" />
        <div style={{ display: "flex", flexDirection: "column", gap: "14px" }}>
          {auth?.access_token &&
            userNavbarData.map((item) => (
              <NavbarItem
                badge={0}
                content={item.text}
                pathname={item.path}
                key={item.path}
                icon={item.icon}
              />
            ))}
          {auth_business?.access_token &&
            businessNavbarData.map((item) => (
              <NavbarItem
                badge={0}
                content={item.text}
                pathname={item.path}
                key={item.path}
                icon={item.icon}
              />
            ))}
        </div>
      </div>
      <div></div>
      <BottomNavbarItem />
    </div>
  );
};

export default Navbar;
