import { useEffect, useState } from "react";
import { Socket } from "socket.io-client";
import { MapResponse } from "../types/Socket";

const useMapAccessToken = (socket: Socket): [string | null, string | null] => {
    const [mapAccessToken, setMapAccessToken] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
  
    useEffect(() => {
      const loadMapAccessToken = async () => {
        try {
          const data: MapResponse = await new Promise((resolve, reject) => {
            socket.emit("get_map_api");
            socket.once("get_map_api:result", resolve);
            socket.once("get_map_api:error", reject);
          });
  
          if (data.success && data.map_access_token) {
            console.log(data.map_access_token)
            setMapAccessToken(data.map_access_token);
          } else {
            setError(data.message || 'Error fetching access token');
          }
        } catch (error) {
          setError('Failed to fetch map access token');
          console.error('Failed to fetch map access token:', error);
        }
      };
  
      loadMapAccessToken();
  
      return () => {
        socket.off("get_map_api:result");
        socket.off("get_map_api:error");
      };
    }, [socket]);
  
    return [mapAccessToken, error];
  };
  
  export default useMapAccessToken;