// Content of Profile page
import React, { useEffect, useState } from "react";
import { useAuth } from "../../Provider/AuthContext";
import { Button, FormGroup, Input, Label, Row } from "../Components.styles";
import styled from "styled-components";
import { useForm, SubmitHandler } from "react-hook-form";
import { BusinessInfos } from "../../types/model";
import { updateBusiness } from "../../api/api";
import AvatarUpload from "./AvatarUpload";
import { Modal } from "antd";
import Location from "./Location";
const Panel = styled.div`
  flex: 1;
  display: flex;
  gap: 30px;
  flex-direction: column;
  margin: 30px auto;
  padding: 0px 30px;
  max-width: 700px;
`;
const ProfilePanel: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<BusinessInfos>();
  const { auth_business, setAuthBusiness } = useAuth();
  useEffect(() => {
    setValue("email", auth_business?.email);
    setValue("name", auth_business?.name);
    setValue("bio", auth_business?.bio);
    setValue("location", auth_business?.location);
    if (auth_business?.location) {
      setLocation(auth_business?.location);
    }
  }, [auth_business]);

  const [locationModalVisible, setLocationModalVisible] = useState(false);
  const onSubmit: SubmitHandler<BusinessInfos> = async (data) => {
    const response = await updateBusiness(data);
    if (!response.error_message) {
      response.access_token = auth_business?.access_token;
      setAuthBusiness(response);
      alert("The user information has been edited!");
    }
  };

  const [location, setLocation] = useState(auth_business?.location || "");

  return (
    <Panel>
      <AvatarUpload />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <FormGroup>
            <Label htmlFor="email">Email:</Label>
            <Input id="email" type="text" {...register("email", {})} />
            {errors.email && <span>{errors.email.message}</span>}
          </FormGroup>
          <FormGroup>
            <Label htmlFor="name">Name:</Label>
            <Input
              id="name"
              type="name"
              {...register("name", {
                required: "Name is required",
              })}
            />
            {errors.name && <span>{errors.name.message}</span>}
          </FormGroup>
        </Row>
        <Row>
          <FormGroup>
            <Label htmlFor="bio">bio:</Label>
            <Input id="bio" type="bio" {...register("bio")} />
            {errors.bio && <span>{errors.bio.message}</span>}
          </FormGroup>
        </Row>
        <Row>
          <FormGroup>
            <Label htmlFor="location">Location:</Label>
            <Input
              id="location"
              type="location"
              {...register("location", {
                required: "Location is required",
              })}
            />
            {errors.location && <span>{errors.location.message}</span>}
          </FormGroup>
          <Button
            type="button"
            style={{
              width: "60px",
              marginTop: "25px",
              marginBottom: "20px",
              borderRadius: "999px",
            }}
            onClick={() => setLocationModalVisible(true)}
          >
            View
          </Button>
        </Row>
        <Button type="submit" style={{ marginTop: "15px" }}>
          Edit
        </Button>
        <Modal
          title="Location"
          open={locationModalVisible}
          onCancel={() => setLocationModalVisible(false)}
          onOk={() => {
            setValue("location", location);
            setLocationModalVisible(false);
          }}
        >
          <Location
            location={location}
            setLocation={(location) => setLocation(location)}
          />
        </Modal>
      </form>
    </Panel>
  );
};

export default ProfilePanel;
