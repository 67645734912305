import { Space } from "antd";
import ForumItem from "../Forum/ForumItem";
import React from "react";
import { Forum } from "../../types/Forum";

export interface ForumBoardProps {
  setSelectedForum: (index: number) => void;
  forumData: Forum[];
  onUpdate: (index: number) => void;
  onDelete: (index: number) => void;
}

const ForumBoard: React.FC<ForumBoardProps> = ({
  setSelectedForum,
  forumData,
  onUpdate,
  onDelete,
}) => {
  return (
    <Space
      direction="vertical"
      style={{
        width: "100%",
        overflowY: "auto",
        padding: "15px",
        maxHeight: "calc(100vh - 200px)",
        minHeight: "calc(100vh - 300px)"
      }}
    >
      {forumData.length ? forumData.map((forum, index) => (
        <ForumItem
          key={index}
          data={forum}
          onClick={() => setSelectedForum(index)}
          onUpdate={() => onUpdate(index)}
          onDelete={() => onDelete(index)}
        />
      )) : "There are no forums."}
    </Space>
  );
};

export default ForumBoard;
