import { Button, Space } from "antd";
import styled from "styled-components";
import EventCard from "../../Components/Events/EventCard";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { EventInfo } from "../../types/model";
import { getUpcomingEvents } from "../../api/api";
import { useAuth } from "../../Provider/AuthContext";
import { useSearch } from "../../Provider/SearchContext";
const Panel = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: start;
  padding: 0px 40px;
`;

const RsvpsPage = () => {
  const { searchText } = useSearch();
  const [upcomingEvents, setUpcomingEvents] = useState<EventInfo[]>([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { auth } = useAuth();

  const handleGetUpcomingEvents = async () => {
    try {
      setLoading(true);
      const response = await getUpcomingEvents();
      if (response.data) {
        let events = response.data;
        if (searchText) {
          events = events.filter((event) =>
            event.name?.toLowerCase().includes(searchText.toLowerCase())
          );
        }
        setUpcomingEvents(events);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetUpcomingEvents();
  }, [searchText]);

  const createdEvents = upcomingEvents.filter(
    (item) => item.user_id === auth?.user_id
  );
  const attendEvents = upcomingEvents.filter(
    (item) => item.user_id !== auth?.user_id
  );
  return (
    <Panel>
      <Button
        style={{ placeSelf: "end" }}
        size="large"
        onClick={() => navigate("/create-event")}
      >
        + Create Event
      </Button>
      {upcomingEvents.length === 0 ? "There are no upcoming events." : null}
      {createdEvents.length ? (
        <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <h2>Event&nbsp;|&nbsp;Create</h2>
          <div
            style={{
              overflow: "auto",
              display: "flex",
              flexDirection: "row",
              gap: "16px",
              width: "calc(100vw - 400px)",
            }}
          >
            {createdEvents.map((event, index) => (
              <EventCard
                key={index}
                event_id={event.event_id!}
                name={event.name!}
                description={event.description!}
                location={event.location!}
                begin_at={event.begin_at!}
                end_at={event.end_at!}
                rating={event.rating}
                onClick={() => navigate(`/event/${event.event_id}`)}
              />
            ))}
          </div>
        </div>
      ) : null}
      {attendEvents.length ? (
        <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <h2>Event&nbsp;|&nbsp;Attending</h2>
          <div
            style={{
              overflow: "auto",
              display: "flex",
              flexDirection: "row",
              gap: "16px",
              width: "calc(100vw - 400px)",
            }}
          >
            {attendEvents.map((event, index) => (
              <EventCard
                key={index}
                event_id={event.event_id!}
                name={event.name!}
                description={event.description!}
                location={event.location!}
                begin_at={event.begin_at!}
                end_at={event.end_at!}
                rating={event.rating}
                onClick={() => navigate(`/event/${event.event_id}`)}
              />
            ))}
          </div>
        </div>
      ) : null}
    </Panel>
  );
};

export default RsvpsPage;
