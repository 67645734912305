import React, { useState } from "react";
import styled from "styled-components";
import { FormContainer, Title } from "../../Components/Components.styles";
import { useAuth } from "../../Provider/AuthContext";
import ProfileSide from "../../Components/UserProfile/ProfileSide";
import ProfileBoard from "../../Components/UserProfile/ProfileBoard";
import ProfileView from "../../Components/UserProfile/ProfileView";

const UserProfileContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

const UserDataWrapper = styled.div`
  margin-bottom: 20px;
`;

const UserDataLabel = styled.strong`
  font-size: 1.2em;
  color: #333;
`;

const RatedBarsWrapper = styled.div`
  margin-top: 20px;
`;

const RatedBarsList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const RatedBarItem = styled.li`
  margin-bottom: 10px;
`;

const Rating = styled.span`
  color: #ff6600;
  font-weight: bold;
`;

const UserProfile: React.FC = () => {
  const { auth } = useAuth();
  const [previewModal, setPreviewModal] = useState(false);

  return (
    <UserProfileContainer>
      <ProfileBoard />
      {/* {auth && (
        <ProfileView
          data={auth}
          isOpen={previewModal}
          preview
          onClose={() => setPreviewModal(false)}
        />
      )} */}
      {/* <ProfileSide handlePreview={() => setPreviewModal(true)} /> */}
    </UserProfileContainer>
  );
};

export default UserProfile;
