import { Col, Input, InputNumber, Modal, Row, Select, Space } from "antd";
import { Ticket } from "../../types/Ticket";
import { useEffect, useState } from "react";
import TextArea from "antd/es/input/TextArea";

const { Option } = Select;

export interface TicketModalProps {
  open: boolean;
  onOk: (data: Ticket) => void;
  onCancel: () => void;
  data: Ticket | undefined;
  type: "create" | "edit";
  event_id: string;
}

const TicketModal: React.FC<TicketModalProps> = ({
  open,
  onOk,
  onCancel,
  data,
  type = "create",
  event_id,
}) => {
  const [ticket, setTicket] = useState<Ticket>(
    data || {
      type: "common",
      price: 1000,
      event_id: parseInt(event_id),
      description: "",
    }
  );
  useEffect(() => {
    if (data) setTicket(data);
  }, [data]);
  return (
    <Modal
      title={type === "create" ? "Create New Ticket" : "Update Ticket"}
      open={open}
      onOk={() => onOk(ticket)}
      onCancel={onCancel}
    >
      <Row
        gutter={[16, 16]}
        style={{ marginTop: "16px", alignItems: "center" }}
      >
        <Col span={6} style={{ textAlign: "right" }}>
          Type:
        </Col>
        <Col span={16}>
          <Select
            value={ticket.type}
            style={{ width: "100%" }}
            onChange={(value) => setTicket({ ...ticket, type: value })}
          >
            <Option value="early_bird">Early bird</Option>
            <Option value="vip">VIP</Option>
            <Option value="common">Common Member</Option>
          </Select>
        </Col>
        <Col span={6} style={{ textAlign: "right" }}>
          Price:
        </Col>
        <Col span={16}>
          <InputNumber
            min={1}
            style={{ width: "100%" }}
            addonAfter="$"
            value={ticket.price}
            onChange={(value) =>
              value && setTicket({ ...ticket, price: value })
            }
          />
        </Col>
        <Col span={24}>
          <Row gutter={[16, 16]}>
            <Col span={6} style={{ textAlign: "right" }}>
              Description:
            </Col>
            <Col span={16}>
              <TextArea
                value={ticket.description}
                onChange={(event) =>
                  setTicket({ ...ticket, description: event.target.value })
                }
                rows={5}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default TicketModal;
