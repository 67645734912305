import { Button, Card, Col, Input, Modal, Row, Space, Table } from "antd";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { SignupResponse } from "../../types/auth";
import { getFriends, findFriends, addFriend } from "../../api/api";
import { toast } from "react-toastify";
import ProfileView from "../../Components/UserProfile/ProfileView";
import { EyeOutlined, MessageOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useSearch } from "../../Provider/SearchContext";

const API_URL = process.env.REACT_APP_API_URL || "https://api.nook.today";

const Panel = styled.div`
  height: 100%;
  padding: 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: #202a16;
  border-radius: 16px;
  align-items: start;
`;

const MyConnectionsPage = () => {
  const navigate = useNavigate();
  const { searchText } = useSearch();
  const [friends, setFriends] = useState<SignupResponse[]>([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [search, setSearch] = useState("");
  const [modalLoading, setModalLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<SignupResponse[]>([]);
  const [viewProfile, setViewProfile] = useState(false);
  const [profileData, setProfileData] = useState({});

  const columns = [
    {
      title: "ID",
      dataIndex: "discount_id",
      key: "discount_id",
      render: (_: any, __: any, index: number) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "username",
      key: "username",
      render: (username: string, row: any) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <img
            src={row.avatar_url || "user_placeholder.png"}
            width={30}
            height={30}
            alt={row.avatar_url}
            style={{
              borderRadius: "50px",
            }}
          />
          <div>{username}</div>
        </div>
      ),
    },
    {
      title: " ",
      dataIndex: "action",
      key: "action",
      render: (_: any, row: any) => (
        <Space>
          <Button
            shape="circle"
            type="default"
            onClick={() => {
              setViewProfile(true);
              setProfileData(row);
            }}
            icon={<EyeOutlined />}
          />
          <Button
            shape="circle"
            type="primary"
            onClick={() => navigate(`/chats/${row.user_id}`)}
            icon={<MessageOutlined />}
          />
        </Space>
      ),
    },
  ];
  const handleGetFriends = async () => {
    try {
      setLoading(true);
      const response = await getFriends();
      if (response.data) {
        let friends = response.data;
        if(searchText) {
          friends = friends.filter((friend) =>
            friend.username?.toLowerCase().includes(searchText.toLowerCase())
          );
        }
        setFriends(friends);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleGetSearchResult = async () => {
    try {
      setModalLoading(true);
      const response = await findFriends(search);
      console.log(response);
      if (response.data) {
        setSearchResults(response.data);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setModalLoading(false);
    }
  };

  const connectFriend = async (user_id: number) => {
    try {
      const response = await addFriend(user_id);
      if (response.data) {
        toast.success("Successed to add connection.");
        setSearchResults((prev) =>
          prev.filter((item) => item.user_id !== user_id)
        );
        handleGetFriends();
      }
    } catch (err) {
      console.error(err);
      toast.error("Failed to add connection");
    }
  };

  useEffect(() => {
    handleGetFriends();
  }, [searchText]);

  return (
    <Panel>
      <h1>My Connections</h1>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Button
          type="primary"
          onClick={() => {
            setModalVisible(true);
          }}
          style={{ float: "right" }}
        >
          Add connection +
        </Button>
        <Row
          gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}
          style={{
            width: "100%",
            margin: 0,
          }}
        >
          {friends.map((friend, index) => (
            <Col key={index} xs={24} sm={12} md={8} lg={6}>
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "16px",
                  alignItems: "center",
                  borderRadius: "8px",
                  border: "2px solid #5A4803",
                  backgroundColor: "#202A16",
                  paddingRight: "12px",
                  height: "80px",
                  width: "fit-content",
                }}
              >
                <div
                  style={{
                    gap: "12px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "240px",
                  }}
                >
                  <img
                    src={
                      friend.avatar_url
                        ? API_URL + "/uploads/" + friend.avatar_url
                        : "user_placeholder.png"
                    }
                    width={80}
                    height={80}
                    alt={friend.avatar_url}
                    style={{
                      borderRadius: "8px 4px 4px 8px",
                      border: "2px solid #5A4803",
                      marginLeft: "-2px",
                    }}
                  />
                  <div
                    style={{
                      gap: "2px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ fontSize: "16px" }}>{friend.username}</div>
                    <div style={{ fontSize: "12px", color: "#BEC2BA" }}>
                      {friend.email}
                    </div>
                  </div>
                </div>
                <Space>
                  <Button
                    shape="circle"
                    type="default"
                    onClick={() => {
                      setViewProfile(true);
                      setProfileData(friend);
                    }}
                    icon={<EyeOutlined />}
                  />
                  <Button
                    shape="circle"
                    type="primary"
                    onClick={() => navigate(`/chats/${friend.user_id}`)}
                    icon={<MessageOutlined />}
                  />
                </Space>
              </div>
            </Col>
          ))}
        </Row>
        {/* <Table dataSource={friends} columns={columns} loading={loading} /> */}
      </Space>
      <Modal
        title="Add Connection + "
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={() => setModalVisible(false)}
        loading={modalLoading}
      >
        <Space direction="vertical" style={{ width: "100%", margin: "10px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
            }}
          >
            <Input
              style={{ width: "340px" }}
              placeholder="Input name here..."
              value={search}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleGetSearchResult();
                }
              }}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Button
              type="primary"
              onClick={() => {
                handleGetSearchResult();
              }}
            >
              Search
            </Button>
          </div>
          <Space
            direction="vertical"
            style={{
              width: "90%",
              padding: "10px",
              minHeight: "300px",
              overflowY: "auto",
            }}
          >
            {searchResults.length
              ? searchResults.map((user) => (
                  <Card hoverable size="small">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Space style={{ alignItems: "center", gap: "10px" }}>
                        <img
                          src={user.avatar_url || "user_placeholder.png"}
                          width={30}
                          height={30}
                          alt={user.username}
                          style={{
                            borderRadius: "50px",
                          }}
                        />
                        <div>{user.username}</div>
                      </Space>
                      <Button
                        type="primary"
                        onClick={() =>
                          user.user_id && connectFriend(user.user_id)
                        }
                      >
                        Connect
                      </Button>
                    </div>
                  </Card>
                ))
              : "No data."}
          </Space>
        </Space>
      </Modal>
      <ProfileView
        isOpen={viewProfile}
        onClose={() => setViewProfile(false)}
        data={profileData}
      />
    </Panel>
  );
};

export default MyConnectionsPage;
